import * as fcl from "@onflow/fcl"
import * as t from "@onflow/types"
import { replaceImports } from "./helper"

import changeNametagScript from '@/cadence/transactions/change_nametag.cdc'

const CODE = replaceImports(changeNametagScript)

export async function changeNametag(code) {
    const txId = await fcl
        .send([
            fcl.transaction(fcl.cdc(CODE)),
            fcl.payer(fcl.authz), // current user is responsible for paying for the transaction
            fcl.proposer(fcl.authz), // current user acting as the nonce
            fcl.authorizations([fcl.authz]), // current user will be first AuthAccount
            fcl.limit(100), // set the compute limit
            fcl.args([fcl.arg(code, t.String)])
        ])
        .then(fcl.decode)

    return fcl.tx(txId).onceSealed()
}