<template>
  <v-container class="mt-4">
    <v-row justify="center" align="center" class="mt-4">
      <v-col cols="12" md="5" align="center">
        <h1>@{{ name }}</h1>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-btn
        depressed
        :class="collectionTabClass"
        class="rounded-pill pa-2 px-3 font-weight-medium grey--text text--darken-2 mr-2"
        @click="changeTab('collection')"
        >Collection</v-btn
      >

      <v-btn
        depressed
        :class="saleTabClass"
        class="rounded-pill pa-2 px-3 font-weight-medium grey--text text--darken-2 mr-2 mb-4"
        @click="changeTab('sale')"
        >For auction</v-btn
      >
    </v-row>
    <v-row>
      <v-divider />
    </v-row>
    <v-row v-if="isTab('collection')" justify="center" aling="center">
      <v-col
        v-for="record in records"
        :key="record.recordID"
        cols="10"
        md="6"
        lg="4"
        xl="3"
        align="center"
      >
        <GenericCard :id="record.recordID" />
      </v-col>
    </v-row>
    <v-row v-if="isTab('sale')" justify="center" aling="center">
      <v-col
        v-for="record in auctions"
        :key="record.recordID"
        cols="10"
        md="6"
        lg="4"
        xl="3"
        align="center"
      >
        <GenericCard :id="record.recordID" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getRecordsUser } from "@/services/RecordService";
import GenericCard from "@/components/GenericCard.vue";

export default {
  name: "Profile",
  components: {
    GenericCard,
  },
  data() {
    return {
      name: "",
      slug: "",
      records: [],
      auctions: [],
      tab: "collection",
      activeClass: "amber lighten-4",
      inactiveClass: "yellow lighten-5",
      collectionTabClass: null,
      saleTabClass: null,
    };
  },
  watch: {
    $route() {
      this.records = []
      this.loadRoute();
      this.loadProfileName();
      this.loadRecords();
      this.setTabClass();
    },
  },
  methods: {
    loadRoute() {
      this.slug = this.$route.params.id;
    },
    async loadRecords() {
      let { records } = await getRecordsUser(this.slug);
      this.records = records;
      this.auctions = this.records.filter((x) => x.status !== "none");
    },
    async loadProfileName() {
      this.name = this.slug;
    },
    changeTab(tab) {
      this.tab = tab;

      this.setTabClass();
    },
    isTab(tab) {
      return this.tab === tab;
    },
    setTabClass() {
      this.collectionTabClass = this.inactiveClass;
      this.saleTabClass = this.inactiveClass;

      if (this.tab === "collection") this.collectionTabClass = this.activeClass;
      else if (this.tab === "sale") this.saleTabClass = this.activeClass;
    },
  },
  created() {
    this.loadRoute();
    this.loadProfileName();
    this.loadRecords();
    this.setTabClass();
  },
};
</script>

