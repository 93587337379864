<template>
  <v-container class="py-0">
    <v-row justify="center" align="center">
      <h2 class="grey--text text--darken-2">Record</h2>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ViewRecordHeader",
  props: [],
  methods: {},
};
</script>