import {
    announcesCollection
} from "@/firebase";

export function getAnnounces(limit = 3, pagination = null) {
    var query = announcesCollection.orderBy("index", "asc")
    if (pagination)
        query = query.startAfter(pagination)

    return query.limit(limit).get()
}