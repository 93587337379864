<template>
  <v-container class="mt-4" fluid>
    <v-row justify="center" class="yellow lighten-5" min-height="100px" fluid>
      <div style="min-height: 100px"></div>
    </v-row>
    <v-row justify="center" class="yellow lighten-5" fluid>
      <transition name="slide-fade">
      <p v-if="showTitle" class="text-h3 mx-5 mb-5" transition="scroll-y-transition">A marketplace for unreleased songs.</p>
      </transition>
      <p v-if="!showTitle" class="text-h3 mx-5 mb-5 yellow--text text--lighten-5" >A marketplace for unreleased songs.</p>
    </v-row>
    <v-row justify="center" align="center" class="yellow lighten-5" fluid>
      <transition name="slide-inv-fade">
      <router-link to="/getstarted"  v-if="showTitle" class="text-decoration-none text-h6 text-center mt-5 mb-0 mr-5"><span
                    class="mt-5 amber lighten-1 rounded-pill pa-2 px-4 font-weight-medium white--text text--darken-2"
                    >Get started</span
                  ></router-link>
      </transition>
      <transition name="slide-inv-fade">
      <v-hover v-if="showTitle" class="text-body-1 text-center mt-5 mb-0" v-slot="{ hover }">
        <router-link
          to="/about"
          :class="{ 'text-decoration-none': !hover }"
          class="black--text pl-0"
        >
          Learn more
        </router-link>
      </v-hover>
      </transition>
      <v-hover v-if="!showTitle" class="yellow--text text--lighten-5 text-body-1 text-center mt-5 mb-0" v-slot="{ hover }">
        <router-link
          to="/about"
          :class="{ 'text-decoration-none': !hover }"
          class="black--text pl-0"
        >
          Learn more
        </router-link>
      </v-hover>
    </v-row>
    <v-row justify="center" class="yellow lighten-5" min-height="100px" fluid>
      <div style="min-height: 100px"></div>
    </v-row>
    <v-row>
      <v-divider/>
    </v-row>
    <v-row v-for="announce in announces" :key="announce.title" class="my-5">
      <v-col>
        <v-row justify="center" align="center" class="mt-5">
          <p class="text-h4 mx-5 mb-2">{{ announce.title }}</p>
        </v-row>
        <v-row justify="center" align="center">
          <p class="mx-5 text-subtitle-1 mt-0">
            {{ announce.description }}
          </p>
        </v-row>
        <v-row justify="center" aling="center">
          <v-col
            v-for="record in announce.recordIDs"
            :key="record"
            cols="12"
            md="6"
            lg="4"
            xl="3"
            align="center"
            class="pa-0"
          >
            <GenericCard :id="record" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GenericCard from "@/components/GenericCard.vue";
import { getAnnounces } from "@/services/AnnounceService";

export default {
  name: "Home",
  components: {
    GenericCard,
  },
  data: () => ({
    announces: [],
    loading: false,
    showTitle: false,
  }),
  methods: {
    async fetchAnnounces() {
      this.announces = [];
      try {
        let query = await getAnnounces(2);
        query.docs.forEach((doc) => {
          const { title, description, recordIDs } = doc.data();
          this.announces.push({ title, description, recordIDs });
        });
        this.showTitle = true
      } catch(error) {
        console.error(`While getting announced: ${error}`)
      }
    },
  },
  created() {
    this.fetchAnnounces();
  },
};
</script>

<style>
.slide-fade-enter-active {
  transition: all .8s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(15px);
  opacity: 0;
}

.slide-inv-fade-enter-active {
  transition: all .8s ease;
}
.slide-inv-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-inv-fade-enter, .slide-inv-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(-15px);
  opacity: 0;
}
</style>

