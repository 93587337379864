export function replaceImports(str) {
    return str.replace("0xFUNGIBLETOKEN", process.env.VUE_APP_CONTRACT_FUNGIBLE)
        .replace("0xNFT", process.env.VUE_APP_CONTRACT_NFT)
        .replace("0xFUSD", process.env.VUE_APP_CONTRACT_FUSD)
        .replace("0xARTISTREGISTERY", process.env.VUE_APP_CONTRACT_MAIN)
        .replace("0xBAGUETTEAUCTION", process.env.VUE_APP_CONTRACT_MAIN)
        .replace("0xBAGUETTEOFFER", process.env.VUE_APP_CONTRACT_MAIN)
        .replace("0xBAGUETTESALE", process.env.VUE_APP_CONTRACT_MAIN)
        .replace("0xRECORD", process.env.VUE_APP_CONTRACT_MAIN)
        .replace("0xNAMETAG", process.env.VUE_APP_CONTRACT_NAMETAG)
}