<template>
  <v-container>
    <v-dialog v-model="showBidPopUp" max-width="330">
      <v-card v-if="displayBidPopUpState('loading')">
        <v-card-title> Loading </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            loading
            disabled
            color="blue-grey"
            class="ma-2 white--text"
            fab
          >
            <v-icon dark> mdi-cloud-upload </v-icon>
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
      <v-card v-if="displayBidPopUpState('noauth')">
        <v-card-title class="headline"> Need log in </v-card-title>
        <v-card-text class="my-2">
          <p class="my-1 text-justify">
            You will need to log in to be able to place an bid.
          </p>
        </v-card-text>

        <v-card-actions>
          <v-row justify="center">
            <v-col cols="6" align="center">
              <v-btn
                depressed
                color="grey lighten-3"
                @click="
                  showBidPopUp = false;
                  bidpopupstate = 'none';
                  amount = null;
                "
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
      <v-card v-if="displayBidPopUpState('errorplacebid')">
        <v-card-title> Error </v-card-title>
        <v-card-text class="my-2">
          <p class="my-1 text-justify">
            An error occured. You might not have sufficient funds or a higher
            bid has already been made. Please allow for a couple of minutes for
            the website to update.

            {{ error }}
          </p>
        </v-card-text>

        <v-card-actions>
          <v-row justify="center">
            <v-col cols="6" align="center">
              <v-btn
                depressed
                color="grey lighten-3"
                @click="showBidPopUp = false"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
      <v-card v-if="displayBidPopUpState('placebid')">
        <v-card-title class="headline"> Place a bid </v-card-title>
        <v-card-text class="my-2">
          <v-row> Enter flow amount below.</v-row>
          <v-row> Minimum bid at ₣USD {{ startPrice }} </v-row>
          <v-row justify="center">
            <v-text-field
              v-model="amount"
              placeholder="Amount"
              hide-details="true"
              prefix="₣USD"
            ></v-text-field>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="red lighten-4"
            @click="
              bidpopupstate = 'none';
              showBidPopUp = false;
            "
          >
            Close
          </v-btn>
          <v-btn depressed color="blue lighten-4" @click="makeBid()">
            Place bid
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="displayBidPopUpState('validationplacebid')">
        <v-card-title class="headline"> Bid placed </v-card-title>
        <v-card-text class="my-2 text-justify">
          Your bid has been placed for ₣USD {{ amount }}. Please allow for a
          couple of minutes for the website to update.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed color="blue lighten-4" @click="showBidPopUp = false">
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <!-- {{$vuetify.breakpoint.name}} -->
      <ViewAuctionHeader
        :hasstarted="hasStarted"
        :timeuntil="time_until"
        :timeleft="time_left"
      />
    </v-row>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="6" lg="4" class="pt-0 px-4">
        <ViewAuctionDetails
          :maintenance="maintenance"
          :belongstouser="belongsToUser"
          :hasstarted="hasStarted"
          :hasbid="hasBid"
          :actionstatus="actionStatus"
          :bidderlink="bidderLink"
          :nextminbid="data.nextMinBid"
          :currentbid="data.currentBid"
          :bidder="data.leader"
          v-on:bidclicked="
            showBidPopUp = true;
            bidpopupstate = 'placebid';
          "
        />
      </v-col>
      <v-col cols="12" sm="6" lg="4" align="center">
        <ViewImage :coveraddr="data.coveraddr" :audioaddr="data.audioaddr" :audiokey="data.audiokey"/>
      </v-col>
      <v-col cols="12" lg="4" align="center" class="pt-0 pl-4">
        <v-container class="mt-0">
          <ViewDescription
            :ownerlink="ownerLink"
            :artistlink="artistLink"
            :title="data.title"
            :owner="data.owner"
            :artist="data.artist"
            :isSongReleased="isSongReleased"
            :lastprice="data.lastprice"
          />
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getRecordDetails, getArtistSlug, getRecordLastPrices } from "@/services/RecordService";
import {
  getAuctionDetails,
  makeAuctionBid,
} from "@/services/AuctionService.js";
import { mapGetters } from "vuex";

import ViewAuctionHeader from "./viewsection/auctionheader";
import { checkIfChainInteraction } from "@/services/UserService"
import ViewImage from "./viewsection/image";
import ViewAuctionDetails from "./viewsection/auctiondetails";
import ViewDescription from "./viewsection/description";

export default {
  name: "AuctionView",
  props: ["id"],
  components: {
    ViewAuctionHeader,
    ViewImage,
    ViewAuctionDetails,
    ViewDescription,
  },
  data() {
    return {
      data: {},
      time_left: 1,
      time_until: 1,
      showBidPopUp: false,
      bidpopupstate: "none",
      loadingBidPopUp: false,
      amount: null,
      actionStatus: "none",
      error: "",
      maintenance: true
    };
  },
  mounted() {
    checkIfChainInteraction().then(res => {
      this.maintenance = !res.data
    })
  },
  computed: {
    ...mapGetters(["isUserAuth", "getProfile"]),
    isSongReleased: function () {
      return !!this.data.audiokey;
    },
    hasStarted: function () {
      return 0 > Date.parse(this.data.startTime) - Date.parse(new Date());
    },
    ownerLink: function() {
      return "/profile/" + this.data.owner;
    },
    artistLink: function() {
      return "/artist/" + this.data.artistslug;
    },
    bidderLink: function() {
      if (!this.data.leader) return "#";
      else return "/profile/" + this.data.leader;
    },
    belongsToUser: function() {
      return this.data.owner === this.getProfile.username;
    },
    startPrice: function() {
      return parseFloat(this.data.nextMinBid).toFixed(2);
    },
    hasBid: function() {
      return this.data.currentBid && parseFloat(this.data.currentBid) > 0;
    },
  },
  created() {
    this.getAuctionData();
  },
  watch: {
    time_left: {
      handler(value) {
        if (value > 0 || !value) {
          const hours = Math.floor((value / (1000 * 60 * 60)) % 24);
          const days = Math.floor(value / (1000 * 60 * 60 * 24));

          var timeout = 1000;
          if (days > 0) timeout = 1000 * 60 * 10;
          else if (hours > 0) timeout = 1000 * 60;
          setTimeout(() => {
            this.time_left =
              Date.parse(this.data.endTime) - Date.parse(new Date());
          }, timeout);
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
    time_until: {
      handler(value) {
        const hours = Math.floor((value / (1000 * 60 * 60)) % 24);
        const days = Math.floor(value / (1000 * 60 * 60 * 24));

        var timeout = 1000;
        if (!this.data.endTime) timeout = 100;
        else if (value > 1 && days > 0) timeout = 1000 * 60 * 10;
        else if (value > 1 && hours > 0) timeout = 1000 * 60;

        setTimeout(() => {
          if (this.data.endTime)
            this.time_until =
              Date.parse(new Date()) - Date.parse(this.data.startTime);
          else this.time_until += 1;
        }, timeout);
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  methods: {
    async getAuctionData() {
      let data = await getAuctionDetails(this.id);
      
      let record = await getRecordDetails(data.recordID);
      delete record.owner
      
      data = Object.assign({}, data, record);
      
      let artistSlug = await getArtistSlug(data.artistID)
      data["artistslug"] = artistSlug

      let prices = await getRecordLastPrices(data.recordID);
      if(prices.length > 0)
        data["lastprice"] = prices[0].price;

      this.data = data
    },
    displayBidPopUpState(state) {
      if (!this.isUserAuth) return state === "noauth";
      else if (this.loadingBidPopUp) return state === "loading";
      else if (state === "placebid" && this.isUserAuth)
        return this.bidpopupstate === "placebid";
      else if (state === "validationplacebid" && this.isUserAuth)
        return this.bidpopupstate === "validationplacebid";
      else if (state === "errorplacebid")
        return this.bidpopupstate === "errorplacebid";
    },
    makeBid() {
      this.loadingBidPopUp = true;
      makeAuctionBid(
        this.data.adminaddr,
        parseInt(this.data.recordID),
        parseFloat(this.amount)
      )
        .then(() => {
          this.bidpopupstate = "validationplacebid";
          this.loadingBidPopUp = false;
          this.actionStatus = "bidplaced";
        })
        .catch((error) => {
          this.error = error
          this.bidpopupstate = "errorplacebid";
          this.loadingBidPopUp = false;
        });
    },
  },
};
</script>
