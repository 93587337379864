<template>
  <v-container class="py-0">
    <v-row justify="center" align="center">
      <h2 class="grey--text text--darken-2">In Auction</h2>
    </v-row>
    <v-row v-if="hasstarted" justify="center" align="center">
      <p class="py-0 mb-0 text-center text-subtitle-2">
        {{ timeRemaining() }}
      </p>
    </v-row>
    <v-row v-if="hasstarted" justify="center" align="center">
      <p class="py-0 text-center text-caption">Time remaining</p>
    </v-row>
    <v-row v-if="!hasstarted" justify="center" align="center">
      <p class="py-0 mb-0 text-center text-subtitle-2">
        {{ timeBeforeStart() }}
      </p>
    </v-row>
    <v-row v-if="!hasstarted" justify="center" align="center">
      <p class="py-0 text-center text-caption">Time until auction</p>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ViewAuctionHeader",
  props: ["hasstarted", "timeuntil", "timeleft"],
  methods: {
    timeRemaining() {
      const seconds = Math.floor((this.timeleft / 1000) % 60);
      const minutes = Math.floor((this.timeleft / 1000 / 60) % 60);
      const hours = Math.floor((this.timeleft / (1000 * 60 * 60)) % 24);
      const days = Math.floor(this.timeleft / (1000 * 60 * 60 * 24));

      if (this.timeleft < 0) return "DONE";

      let seconds_str = seconds.toString();
      if (seconds < 10) seconds_str = "0" + seconds_str;

      let minutes_str = minutes.toString();
      if (minutes < 10) minutes_str = "0" + minutes_str;

      if (days > 1)
        if (hours > 0) return `${days} days ${hours}h`;
        else return `${days} days`;
      else if (days > 0)
        if (hours > 0) return `${days} day ${hours}h`;
        else return `${days} day`;
      else if (hours > 0)
        if (minutes > 0) return `${hours}h ${minutes_str}min`;
        else return `${minutes_str}h`;
      else if (minutes > 10) return `${minutes_str}min`;
      else if (minutes > 0) return `00:${minutes_str}:${seconds_str}`;
      return `00:00:${seconds_str}`;
    },
    timeBeforeStart() {
      const seconds = Math.floor((this.timeuntil / 1000) % 60);
      const minutes = Math.floor((this.timeuntil / 1000 / 60) % 60);
      const hours = Math.floor((this.timeuntil / (1000 * 60 * 60)) % 24);
      const days = Math.floor(this.timeuntil / (1000 * 60 * 60 * 24));

      let seconds_str = seconds.toString();
      if (seconds < 10) seconds_str = "0" + seconds_str;

      let minutes_str = minutes.toString();
      if (minutes < 10) minutes_str = "0" + minutes_str;

      if (days > 1)
        if (hours > 0) return `${days} days ${hours}h`;
        else return `${days} days`;
      else if (days > 0)
        if (hours > 0) return `${days} day ${hours}h`;
        else return `${days} day`;
      else if (hours > 0)
        if (minutes > 0) return `${hours}h ${minutes_str}min`;
        else return `${minutes_str}h`;
      else if (minutes > 10) return `${minutes_str}min`;
      else if (minutes > 0) return `00:0${minutes_str}:${seconds_str}`;
      return `00:00:${seconds_str}`;
    },
  },
};
</script>