<template>
  <v-container class="mt-4">
    <v-row justify="center" align="center" class="mt-4">
      <v-col cols="12" md="5" align="center">
        <h1>{{name}}</h1>
        <p class="text-subtitle-1 mb-0">{{ records.length }} songs</p>
      </v-col>
    </v-row>
    <v-row>
      <v-divider/>
    </v-row>
    <v-row justify="center" aling="center">
      <v-col
        v-for="record in records"
        :key="record"
        cols="10"
        md="6"
        lg="4"
        xl="3"
        align="center"
      >
        <GenericCard :id="record" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getArtistName, getRecordsByArtistSlug } from "@/services/RecordService"
import GenericCard from "@/components/GenericCard.vue"
export default {
  name: "Artist",
  components: {
    GenericCard
  },
  data() {
    return {
      slug: "",
      name: "",
      loading: false,
      records: [],
    };
  },
  methods: {
    loadRoute() {
      this.slug = this.$route.params.id;
    },
    async loadSongs() {
      this.name = await getArtistName(this.slug)

      this.records = []
      this.loading = true

      this.records = (await getRecordsByArtistSlug(this.slug, 10)).docs.map(x => x.id)
      this.loading = false
    }
  },
  created() {
    this.loadRoute();
    this.loadSongs()
  },
};
</script>

