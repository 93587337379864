import * as fcl from "@onflow/fcl"
import * as t from "@onflow/types"
import { replaceImports } from "./helper"

import acceptDirectOfferScript from '@/cadence/transactions/accept_direct_offer.cdc'

const CODE = replaceImports(acceptDirectOfferScript)

export async function acceptDirectOfferTx(recordid) {
    const txId = await fcl
        .send([
            fcl.transaction(fcl.cdc(CODE)),
            fcl.payer(fcl.authz), // current user is responsible for paying for the transaction
            fcl.proposer(fcl.authz), // current user acting as the nonce
            fcl.authorizations([fcl.authz]), // current user will be first AuthAccount
            fcl.limit(1000), // set the compute limit
            fcl.args([fcl.arg(process.env.VUE_APP_OFFERADMIN, t.Address), fcl.arg(recordid, t.UInt64)])
        ])
        .then(fcl.decode)

    return fcl.tx(txId).onceSealed()
}