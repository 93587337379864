<template>
  <v-container class="py-0">
    <v-row align="center" class="mt-0 pt-4 amber lighten-2">
      <v-col v-if="hasstarted && !belongstouser && isauth" cols="4" class="pt-0 mb-4">
        <v-btn
          v-if="hasstarted"
          small
          depressed
          :disabled="timeleft < 0"
          class="grey lighten-2"
          :to="recordlink"
          >place a bid</v-btn
        >
      </v-col>
      <v-col v-if="!hasstarted || belongstouser || !isauth" cols="4" class="pt-0 mb-4">
        <v-btn
          v-if="hasstarted"
          small
          depressed
          :disabled="timeleft < 0"
          class="grey lighten-2"
          :to="recordlink"
          >Check auction</v-btn
        >
      </v-col>
      <v-col v-if="hasstarted" cols="8" class="pt-0">
        <v-container class="pt-0 mb-4">
          <v-row class="mt-0 text-right text-subtitle-2" justify="end">
              {{ timeRemaining() }}
          </v-row>
          <v-row class="mt-2 text-right text-caption" justify="end">
              Time remaining
          </v-row>
        </v-container>
      </v-col>
      <v-col v-if="!hasstarted" cols="12" class="pt-0">
        <v-container class="pt-0 mb-4">
          <v-row class="mt-0 text-right text-subtitle-2" justify="end">
              {{ timeBeforeStart() }}
          </v-row>
          <v-row class="mt-2 text-right text-caption" justify="end">
              Time until auction
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "CardAuctionFooter",
  props: ["recordlink", "belongstouser", "hasstarted", "isauth", "timeuntil", "timeleft"],
  methods: {
    timeRemaining() {
      const seconds = Math.floor((this.timeleft / 1000) % 60);
      const minutes = Math.floor((this.timeleft / 1000 / 60) % 60);
      const hours = Math.floor((this.timeleft / (1000 * 60 * 60)) % 24);
      const days = Math.floor(this.timeleft / (1000 * 60 * 60 * 24));

      if (this.timeleft < 0) return "DONE";

      let seconds_str = seconds.toString()
      if(seconds < 10)
        seconds_str = "0" + seconds_str

        let minutes_str = minutes.toString()
      if(minutes < 10)
        minutes_str = "0" + minutes_str

      if (days > 1)
        if (hours > 0) return `${days} days ${hours}h`;
        else return `${days} days`;
      else if (days > 0)
        if (hours > 0) return `${days} day ${hours}h`;
        else return `${days} day`;
      else if (hours > 0)
        if (minutes > 0) return `${hours}h ${minutes_str}min`;
        else return `${minutes_str}h`;
      else if (minutes > 10) return `${minutes_str}min`;
      else if (minutes > 0) return `00:${minutes_str}:${seconds_str}`;
      return `00:00:${seconds_str}`;
    },
    timeBeforeStart() {
      const seconds = Math.floor((this.timeuntil / 1000) % 60);
      const minutes = Math.floor((this.timeuntil / 1000 / 60) % 60);
      const hours = Math.floor((this.timeuntil / (1000 * 60 * 60)) % 24);
      const days = Math.floor(this.timeuntil / (1000 * 60 * 60 * 24));

      let seconds_str = seconds.toString()
      if(seconds < 10)
        seconds_str = "0" + seconds_str

        let minutes_str = minutes.toString()
      if(minutes < 10)
        minutes_str = "0" + minutes_str

      if (days > 1)
        if (hours > 0) return `${days} days ${hours}h`;
        else return `${days} days`;
      else if (days > 0)
        if (hours > 0) return `${days} day ${hours}h`;
        else return `${days} day`;
      else if (hours > 0)
        if (minutes > 0) return `${hours}h ${minutes_str}min`;
        else return `${minutes_str}h`;
      else if (minutes > 10) return `${minutes_str}min`;
      else if (minutes > 0) return `00:0${minutes_str}:${seconds_str}`;
      return `00:00:${seconds_str}`;
    }
  },
};
</script>