import * as fcl from "@onflow/fcl"
import * as t from "@onflow/types"
import { replaceImports } from "./helper"

import cancelOfferScript from '@/cadence/transactions/cancel_offer.cdc'

const CODE = replaceImports(cancelOfferScript)

export async function cancelOfferTx(recordid) {
    const txId = await fcl
        .send([
            fcl.transaction(fcl.cdc(CODE)),
            fcl.payer(fcl.authz), 
            fcl.proposer(fcl.authz), 
            fcl.authorizations([fcl.authz]), 
            fcl.limit(1000), 
            fcl.args([fcl.arg(process.env.VUE_APP_OFFERADMIN, t.Address), fcl.arg(recordid, t.UInt64)])
        ])
        .then(fcl.decode)

    return fcl.tx(txId).onceSealed()
}