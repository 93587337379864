<template>
  <v-sheet outlined color="yellow lighten-5" max-width="325">
    <v-sheet class="rounded-lg overflow-hidden" :color="fillcolor">
      <v-container class="my-0">
        <v-row>
          <v-col cols="12" class="pa-0">
            <CardImage
              :recordlink="recordLink"
              :coveraddr="data.coveraddr" 
              :audioaddr="data.audioaddr" 
              :audiokey="data.audiokey"
              audiourl="https://file-examples-com.github.io/uploads/2017/11/file_example_MP3_700KB.mp3"
            />
          </v-col>
        </v-row>
        <v-row>
          <CardDescription
            :recordlink="recordLink"
            :artistlink="artistLink"
            :title="data.title"
            :artist="data.artist"
            :isSongReleased="isSongReleased"
          />
        </v-row>
        <v-row justify="center">
          <v-divider class="mb-3 mt-2 mx-3" />
        </v-row>
        <v-row>
          <CardRecordPrice
            :recordlink="recordLink"
            :offererlink="offerorLink"
            :nextminoffer="data.nextMinOffer"
            :currentoffer="data.currentOffer"
            :offerer="offerorName"
          />
        </v-row>
        <v-row justify="center">
          <v-divider class="mb-3 mt-3 mx-3" />
        </v-row>
        <v-row>
          <CardRecordFooter
            :recordlink="recordLink"
            :ownerlink="ownerLink"
            :owner="ownerName"
            :belongstouser="belongsToUser"
            :isauth="isUserAuth"
          />
        </v-row>
      </v-container>
    </v-sheet>
  </v-sheet>
</template>

<script>
import { getRecordDetails, getRecordOfferID, getArtistSlug } from "@/services/RecordService";
import * as offersvc from "@/services/OfferService.js";
import { mapGetters } from "vuex";

import CardImage from "./cardsections/image";
import CardDescription from "./cardsections/description";
import CardRecordPrice from "./cardsections/recordprice";
import CardRecordFooter from "./cardsections/recordfooter";

export default {
  name: "RecordCard",
  props: ["id"],
  components: {
    CardImage,
    CardDescription,
    CardRecordPrice,
    CardRecordFooter,
  },
  data() {
    return {
      data: {},
      fillcolor: "amber lighten-4",
    };
  },
  created() {
    this.getRecordData();
  },
  computed: {
    ...mapGetters(["isUserAuth", "getProfile"]),
    isSongReleased: function () {
      return !!this.data.audiokey;
    },
    outlineColor: function () {
      if (this.data.url !== "unreleased") return "black";
      else return "black";
    },
    recordLink: function() {
      return "/record/" + this.data.recordID;
    },
    ownerLink: function() {
      return "/profile/" + this.data.owner;
    },
    ownerName: function() {
      if (!this.data.owner) return "No owner";
      else return this.data.owner;
    },
    offerorName: function() {
      return this.data.offeror;
    },
    offerorLink: function() {
      if (!this.data.offeror) return "#";
      return "/profile/" + this.offerorName;
    },
    artistLink: function() {
      return "/artist/" + this.data.artistslug;
    },
    belongsToUser: function() {
      return this.data.owner === this.getProfile.username;
    },
    hasOffer: function() {
      return this.data.currentOffer && parseFloat(this.data.currentOffer) > 0;
    },
    hasMadeOffer: function() {
      return (
        this.hasOffer && this.offerorName === this.getProfile.username
      );
    },
    currentOffer: function() {
      return parseFloat(this.data.currentOffer).toFixed(2);
    },
    startingPrice: function() {
      if(!this.data.nextMinOffer) return 0.0
      return parseFloat(this.data.nextMinOffer).toFixed(2);
    },
  },
  methods: {
    async getRecordData() {
      let data = await getRecordDetails(this.id);
      let offerid = await getRecordOfferID(this.id);
      if(offerid) {
        let offer = await offersvc.getOfferDetails(offerid)
        data = Object.assign({}, data, offer);
      }

      let artistSlug = await getArtistSlug(data.artistID)
      data["artistslug"] = artistSlug

      this.data = data
    },
  },
};
</script>