import * as fcl from "@onflow/fcl"
import * as t from "@onflow/types"
import {
    replaceImports
} from "./helper"

import createAuctionScript from '@/cadence/transactions/create_auction.cdc'

const CODE = replaceImports(createAuctionScript)

export async function createAuction(recordid, auctionLength, delayStart, startprice) {
    const txId = await fcl
        .send([
            fcl.transaction(fcl.cdc(CODE)),
            fcl.payer(fcl.authz), // current user is responsible for paying for the transaction
            fcl.proposer(fcl.authz), // current user acting as the nonce
            fcl.authorizations([fcl.authz]), // current user will be first AuthAccount
            fcl.limit(1000), // set the compute limit
            fcl.args([
                fcl.arg(recordid, t.UInt64),
                fcl.arg(auctionLength.toFixed(2), t.UFix64),
                fcl.arg(delayStart.toFixed(2), t.UFix64),
                fcl.arg(startprice.toFixed(2), t.UFix64)
            ])
        ])
        .then(fcl.decode)

    return fcl.tx(txId).onceSealed()
}