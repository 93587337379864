<template>
  <v-sheet outlined color="yellow lighten-5" max-width="325">
    <v-sheet class="rounded-lg overflow-hidden" :color="fillcolor">
      <v-container class="my-0">
        <v-row>
          <v-col cols="12" class="pa-0">
            <CardImage
              :recordlink="recordLink"
              :coveraddr="data.coveraddr" 
              :audioaddr="data.audioaddr" 
              :audiokey="data.audiokey"
            />
          </v-col>
        </v-row>
        <v-row>
          <CardDescription
            :recordlink="recordLink"
            :artistlink="artistLink"
            :title="data.title"
            :artist="data.artist"
            :isSongReleased="isSongReleased"
          />
        </v-row>
        <v-row justify="center">
          <v-divider class="mb-3 mt-2 mx-3" />
        </v-row>
        <v-row>
          <CardAuctionPrice
            :recordlink="recordLink"
            :bidderlink="bidderLink"
            :nextminbid="data.nextMinBid"
            :currentbid="data.currentBid"
            :bidder="data.leader"
          />
        </v-row>
        <v-row justify="center">
          <v-divider class="mt-3 mb-0" style="opacity: 0" />
        </v-row>
        <v-row>
          <CardAuctionFooter
            :recordlink="recordLink"
            :belongstouser="belongsToUser"
            :hasstarted="hasStarted"
            :isauth="isUserAuth"
            :timeuntil="time_until"
            :timeleft="time_left"
          />
        </v-row>
      </v-container>
    </v-sheet>
  </v-sheet>
</template>

<script>
import { getRecordDetails, getArtistSlug } from "@/services/RecordService";
import { getAuctionDetails } from "@/services/AuctionService.js";
import { mapGetters } from "vuex";

import CardImage from "./cardsections/image";
import CardDescription from "./cardsections/description";
import CardAuctionPrice from "./cardsections/auctionprice";
import CardAuctionFooter from "./cardsections/auctionfooter";

export default {
  name: "AuctionCard",
  props: ["id"],
  components: {
    CardImage,
    CardDescription,
    CardAuctionPrice,
    CardAuctionFooter,
  },
  data() {
    return {
      data: {},
      time_left: 1,
      time_until: 1,
      fillcolor: "amber lighten-4",
    };
  },
  created() {
    this.getAuctionData();
  },
  computed: {
    ...mapGetters(["isUserAuth", "getProfile"]),
    isSongReleased: function () {
      return !!this.data.audiokey;
    },
    hasStarted: function () {
      return 0 > Date.parse(this.data.startTime) - Date.parse(new Date());
    },
    recordLink: function() {
      return "/record/" + this.data.recordID;
    },
    ownerLink: function() {
      return "/profile/" + this.data.owner;
    },
    bidderLink: function() {
      if (!this.data.leader) return "#";
      else return "/profile/" + this.data.leader;
    },
    artistLink: function() {
      return "/artist/" + this.data.artistslug;
    },
    belongsToUser: function() {
      return this.data.owner === this.getProfile.username;
    }
  },
  watch: {
    time_left: {
      handler(value) {
        const hours = Math.floor((value / (1000 * 60 * 60)) % 24);
        const days = Math.floor(value / (1000 * 60 * 60 * 24));

        var timeout = 1000;
        if (!this.data.endTime) timeout = 100;
        else if (value > 1 && days > 0) timeout = 1000 * 60 * 10;
        else if (value > 1 && hours > 0) timeout = 1000 * 60;

        setTimeout(() => {
          if (this.data.endTime)
            this.time_left =
              Date.parse(this.data.endTime) - Date.parse(new Date());
          else this.time_left += 1;
        }, timeout);
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
    time_until: {
      handler(value) {
        const hours = Math.floor((value / (1000 * 60 * 60)) % 24);
        const days = Math.floor(value / (1000 * 60 * 60 * 24));

        var timeout = 1000;
        if (!this.data.endTime) timeout = 100;
        else if (value > 1 && days > 0) timeout = 1000 * 60 * 10;
        else if (value > 1 && hours > 0) timeout = 1000 * 60;

        setTimeout(() => {
          if (this.data.endTime)
            this.time_until =
              Date.parse(new Date()) - Date.parse(this.data.startTime);
          else this.time_until += 1;
        }, timeout);
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  methods: {
    async getAuctionData() {
      let data = await getAuctionDetails(this.id);
      
      let record = await getRecordDetails(data.recordID);
      delete record.owner;

      data = Object.assign({}, data, record);

      let artistSlug = await getArtistSlug(data.artistID)
      data["artistslug"] = artistSlug

      this.data = data
    }
  },
};
</script>