import * as fcl from "@onflow/fcl"
import * as t from "@onflow/types"
import { replaceImports } from "./helper"

import makeBidScript from '@/cadence/transactions/make_auction_bid.cdc'

const CODE = replaceImports(makeBidScript)

export async function makeBid(to, recordid, amount) {
    const txId = await fcl
        .send([
            fcl.transaction(fcl.cdc(CODE)),
            fcl.payer(fcl.authz), 
            fcl.proposer(fcl.authz), 
            fcl.authorizations([fcl.authz]), 
            fcl.limit(1000), 
            fcl.args([fcl.arg(to, t.Address), fcl.arg(recordid, t.UInt64), fcl.arg(amount.toFixed(2), t.UFix64)])
        ])
        .then(fcl.decode)

    return fcl.tx(txId).onceSealed()
}