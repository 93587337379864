const axios = require("axios");
import {
    auth
} from '@/firebase'

import {
    usersCollection
} from "@/firebase";

const getConfig = (token, params) => {
    return {
        headers: {
            Authorization: `Bearer ${token}`
        },
        params: params
    };
};

export function getUsername(uid) {
    return usersCollection.doc(uid).get()
        .then(doc => {
            console.log(doc.data())
            if (!doc.exists)
                return null;
            return doc.data().username;
        })
}

export function changeUsername(username) {
    return auth.currentUser.getIdToken().then(function (token) {
        return axios.post(
            process.env.VUE_APP_BACKEND_PATH + '/changeusername', {},
            getConfig(token, {
                name: username
            })
        );
    })
}

export function checkIfUsernameTaken(username) {
    return auth.currentUser.getIdToken().then(function (token) {
        return axios.get(process.env.VUE_APP_BACKEND_PATH + '/usernametaken', getConfig(token, {
            name: username
        }));
    }).then((res) => {
        return res.data.taken;
    });
}

export function checkIfChainInteraction() {
    return axios.get(
        process.env.VUE_APP_BACKEND_PATH + '/chainaccess', {}
    );
}