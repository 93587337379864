const axios = require("axios");

import {
    auctionsCollection
} from "@/firebase";
import { createAuction } from "../cadence/create-auction.tx";
import { makeBid } from "../cadence/make-auction-bid.tx"

export function getLiveAuctions(limit = 3, pagination = null) {
    var query = auctionsCollection.where("hasStarted", "==", true).where("hasEnded", "==", false).orderBy("endTime", "desc")
    if (pagination)
        query = query.startAfter(pagination)

    return query.limit(limit).get()
}

export function getUnstartedAuctions(limit = 3, pagination = null) {
    var query = auctionsCollection.where("hasStarted", "==", false).orderBy("startTime", "asc")
    if (pagination)
        query = query.startAfter(pagination)

    return query.limit(limit).get()
}

export function getAuctionDetails(auctionid) {
    return axios.get(process.env.VUE_APP_BACKEND_PATH + '/auction/get', {
            params: {
                auctionid: auctionid
            }
        })
        .then((res) => {
            return res.data.auction;
        });
}

export function createRecordAuction(recordid, auctionLength, delayStart, startprice) {
    return createAuction(recordid, auctionLength, delayStart, startprice)
}

export function makeAuctionBid(to, recordid, amount) {
    return makeBid(to, recordid, amount)
}