<template>
  <v-sheet outlined color="yellow lighten-5" max-width="325">
    <v-sheet class="rounded-lg overflow-hidden" :color="fillcolor">
      <v-container class="my-0">
        <v-row>
          <CardLoadingImage
          />
        </v-row>
        <v-row>
          <CardDescription
            recordlink="#"
            artistlink="#"
            title="#"
            artist="#"
            style="opacity: 0"
          />
        </v-row>
        <v-row justify="center">
          <v-divider class="mb-3 mt-2 mx-3" />
        </v-row>
        <v-row>
          <CardRecordPrice
            recordlink="#"
            lastprice="#"
            style="opacity: 0"
          />
        </v-row>
        <v-row justify="center">
          <v-divider class="mb-3 mt-3 mx-3" />
        </v-row>
        <v-row>
          <CardRecordFooter
            recordlink="#"
            ownerlink="#"
            owner=""
            belongstouser=""
            style="opacity: 0"
          />
        </v-row>
      </v-container>
    </v-sheet>
  </v-sheet>
</template>

<script>
import CardLoadingImage from "./cardsections/loadingimage";
import CardDescription from "./cardsections/description";
import CardRecordPrice from "./cardsections/recordprice";
import CardRecordFooter from "./cardsections/recordfooter";

export default {
  name: "LoadingCard",
  components: {
    CardLoadingImage,
    CardDescription,
    CardRecordPrice,
    CardRecordFooter,
  },
  data: () => ({
    fillcolor: "brown lighten-5",
  }),
};
</script>
