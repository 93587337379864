var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"py-0 font-weight-medium text-subtitle",attrs:{"justify":"center"}},[_vm._v(" ₣USD "),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('router-link',{staticClass:"text-decoration-none opacityanimated pl-1",class:{
          'black--text': !hover,
          'grey--text text--darken-2': hover,
        },attrs:{"to":_vm.recordlink}},[_vm._v(" "+_vm._s(_vm.getCurrentBid())+" ")])]}}])})],1),(!!this.bidder)?_c('v-row',{staticClass:"pt-0 text-caption",attrs:{"justify":"center"}},[_vm._v(" Current bid by "),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var hover = ref.hover;
return [_c('router-link',{staticClass:"black--text pl-1",class:{ 'text-decoration-none': !hover },attrs:{"to":_vm.bidderlink}},[_vm._v(" @"+_vm._s(_vm.bidder)+" ")])]}}],null,false,4274440317)})],1):_vm._e(),(!this.bidder)?_c('v-row',{staticClass:"pt-0 text-caption",attrs:{"justify":"center"}},[_vm._v(" Starting bid ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }