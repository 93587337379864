// import * as sdk from "@onflow/sdk"

const axios = require("axios");
import {
    auth
} from '@/firebase'

import {
    changeNametag
} from '../cadence/change-nametag.tx'
import {
    prepareWallet
} from '../cadence/prepare-wallet.tx'
import {
    getFUSDBalance
} from '../cadence/get-fusd-balance.sc'
import {
    checkWallet
} from '../cadence/check-wallet.sc'

const getConfig = (token, params) => {
    return {
        headers: {
            Authorization: `Bearer ${token}`
        },
        params: params
    };
};

export function getAddress() {
    return auth.currentUser.getIdToken().then(function (token) {
        return axios.get(process.env.VUE_APP_BACKEND_PATH + '/address', getConfig(token));
    }).then((res) => {
        return res.data.pubaddr;
    });
}

export function addressTaken(address) {
    if (!address)
        return false;

    return auth.currentUser.getIdToken().then(function (token) {
        return axios.get(
            process.env.VUE_APP_BACKEND_PATH + '/addresstaken',
            getConfig(token, {
                pubaddr: address
            })
        );
    }).then((res) => {
        return res.data.pubaddr;
    });
}

export function checkWalletAssociation(address) {
    return getAddress()
        .then((userAddress) => {
            return address === userAddress;
        });
}

export function sendLinkDemand(address) {
    return auth.currentUser.getIdToken().then(function (token) {
        return axios.get(
            process.env.VUE_APP_BACKEND_PATH + '/addresscode',
            getConfig(token, {
                pubaddr: address
            })
        );
    }).then((res) => {
        let code = res.data.code;
        return changeNametag(code.toString());
    }).then(() => {
        return auth.currentUser.getIdToken().then(function (token) {
            return axios.post(
                process.env.VUE_APP_BACKEND_PATH + '/linkaddress', {},
                getConfig(token, {
                    pubaddr: address
                })
            );
        })
    }).catch((error) => {
        console.log(error)
    })
}

export function checkWalletSetup(address) {
    return checkWallet(address)
}

export function setupWallet() {
    return prepareWallet()
}

export async function getWalletBalance(address) {
    return getFUSDBalance(address)
}