<template>
  <div>
    <a
      class="grey rounded-pill pa-2 px-3 font-weight-medium white--text ml-2"
      v-if="!isUserAuth"
      @click.stop="loginpopup = true"
      >Log in / Sign up</a
    >
    <v-dialog v-model="loginpopup" max-width="290">
      <v-card v-if="!emailSent">
        <v-card-title class="headline"> Log in / sign up </v-card-title>

        <v-card-text class="mt-4">
          <v-checkbox v-model="checkbox_login">
            <template v-slot:label>
              <div>
                I've read and accept the 
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a
                      class="text-decoration-none"
                      target="_blank"
                      href="https://www.notion.so/Baguette-Terms-and-Conditions-6b02cc6023ca4681b9a6c911a118495b"
                      @click.stop
                      v-on="on"
                    >
                    terms and conditions
                    </a>
                  </template>
                  Opens in new window
                </v-tooltip>
                and the 
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a
                      class="text-decoration-none"
                      target="_blank"
                      href="https://www.notion.so/Baguette-Privacy-Notice-8d96d1d9641f47a68bf8f93b868b713c"
                      @click.stop
                      v-on="on"
                    >
                      privacy notice
                    </a>
                  </template>
                  Opens in new window
                </v-tooltip>.
              </div>
            </template>
          </v-checkbox>
          <v-text-field
            v-model="email"
            placeholder="Email"
            hide-details="auto"
            dense
            outlined
            :rules="rules_email"
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-row justify="center" class="pb-2">
            <v-col cols="6" align="right">
              <v-btn
                depressed
                :disabled="!isValidInput()"
                color="blue lighten-3"
                @click="sendSignInLink"
              >
                Continue
              </v-btn>
            </v-col>
            <v-col cols="6" align="center">
              <v-btn
                depressed
                color="grey lighten-3"
                @click="loginpopup = false"
              >
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
      <v-card v-if="emailSent">
        <v-card-title class="headline"> Email sent! </v-card-title>

        <v-card-text class="my-2">
          <v-row justify="center">
            <p class="my-1">An email has been sent to the address:</p>
          </v-row>
          <v-row justify="center">
            <p class="my-1 font-weight-medium blue--text text--lighten-2">
              {{ email }}
            </p>
          </v-row>
          <v-row justify="center">
            <p class="my-1">
              Click the link in <b>the same browser</b> to log in or sign up.
            </p>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-row justify="center">
            <v-col cols="6" align="center">
              <v-btn
                depressed
                color="grey lighten-3"
                @click="loginpopup = false"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div
      v-if="isUserAuth"
      @mouseover="setHover(true)"
      @mouseleave="setHover(false)"
      class="d-flex align-center"
    >
      <v-divider vertical></v-divider>
      <a
        class="
          red
          lighten-5
          rounded-pill
          pa-1
          px-3
          font-weight-medium
          red--text
          ml-2
        "
        v-if="hover"
        @click="performLogout"
        >Log out</a
      >
      <router-link class="black--text ml-2" v-if="hover" to="/account">
        Account
      </router-link>
      <v-chip class="ml-2" color="blue lighten-5" pill>
        @{{ getProfile.username }}
      </v-chip>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "AccountMenu",
  data: () => ({
    loading: false,
    loginpopup: false,
    email: null,
    emailSent: false,
    hover: false,
    checkbox_login: false,
    buttonText: "Log in",
    loggedinlinks: [
      {
        text: "Account",
        route: "/",
      },
      {
        text: "Log out",
        route: "/",
      },
    ],
    rules_email: [
      (value) => !!value || "Required.",
      (value) => (value || "").length <= 30 || "Max 30 characters",
      (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    ],
  }),
  computed: {
    ...mapGetters(["isUserAuth", "getSignInEmail", "getProfile"]),
  },
  methods: {
    ...mapActions(["sendAuthEmail", "signInWithLink", "logout"]),
    sendSignInLink() {
      if (this.isValidInput()) {
        this.sendAuthEmail(this.email).then(() => {
          this.emailSent = true;
        });
      }
    },
    isValidInput() {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return (
        !!this.email &&
        (this.email || "").length <= 30 &&
        pattern.test(this.email) &&
        this.checkbox_login
      );
    },
    performLogout() {
      this.logout().then(() => {
        this.$router.push("/");
        this.emailSent = false;
      });
    },
    setHover(hover) {
      this.hover = hover;
    },
  },
};
</script>
