import {config} from "@onflow/fcl"

config()
  .put("accessNode.api", process.env.VUE_APP_ACCESS_NODE) // Configure FCLs Access Node
  .put("challenge.handshake", process.env.VUE_APP_WALLET_DISCOVERY) // Configure FCLs Wallet Discovery mechanism
  .put("0xProfile", process.env.VUE_APP_CONTRACT_PROFILE)

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import { auth } from './firebase'

Vue.config.productionTip = false

let app
auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  }
})
