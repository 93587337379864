<template>
  <v-container class="mt-4" fluid>
    <v-row justify="center" align="center">
      <v-container>
        <v-row justify="center" class="mb-5">
          <p class="text-h4">Get started</p>
        </v-row>
        <v-row justify="center" class="my-5">
          <v-col align="center">
            <v-row style="max-width: 800px" class="px-4 text-left">
              Baguette is a marketplace where you can find never-before-heard
              songs. For each song an artist wishes to sell on Baguette, a
              unique digital record is issued and sold through an auction. No one will
              be able to hear that song before the record owner decides to release
              it, not even the owner!
            </v-row>
            <v-row style="max-width: 800px" class="px-4 pt-1 text-left">
              Collectors will be able to collect, music fans will be able to
              access brand new music pieces, and artists will be able to share
              new songs, private studio sessions of hits or even albums
              in a brand new way. This is a novel medium for fans and artists to
              interact.
            </v-row>
          </v-col>
        </v-row>
        <v-row justify="center" class="my-5">
          <v-col align="center">
            <v-row style="max-width: 800px" class="px-4 text-left">
              <p class="text-h5">Before signing up</p>
            </v-row>
            <v-row style="max-width: 800px" class="px-4 text-left">
              If you want to create your account right away, you can skip to
              the next section and come back later. This section will explain how
              Baguette functions.
            </v-row>
            <v-row style="max-width: 800px" class="px-4 text-left">
              <a
                class="mt-5 ml-5 text-decoration-none"
                href="https://www.onflow.org/"
                >Flow</a
              >
              Baguette runs on the Flow blockchain. It proves ownership of
              records and easily sets up a secondary market where you will be
              able to resell your records.
            </v-row>
            <v-row style="max-width: 800px" class="px-4 text-left">
              <a
                class="mt-5 ml-5 text-decoration-none"
                href="https://blocto.portto.io/"
                >Blocto</a
              >
              Baguette does not hold user funds. When a user pays for a sale or
              auction, the money goes directly to the seller or the auction
              escrow account. In order to transact, users need a wallet which
              will hold their funds.
            </v-row>
            <v-row style="max-width: 800px" class="pt-3 px-4 text-left">
              Blocto is the wallet provider. It allows you to connect to your
              personal wallet using your email address and to securely review
              and sign any transaction you will do on our platform. You will be
              prompted to create and initialize your wallet during the sign up
              process to attach it to your Baguette account.
            </v-row>
            <v-row style="max-width: 800px" class="px-4 text-left">
              <a
                class="mt-5 ml-5 text-decoration-none"
                href="https://swap.blocto.app/#/swap"
                >Blocto Swap</a
              >
              The currency used to transact is the Flow stablecoin ₣USD (1 ₣USD
              = $1). It is backed by Flow's partner Prime Trust.
            </v-row>
            <v-row style="max-width: 800px" class="pt-3 px-4 text-left">
              To top up some ₣USD on your account, you will need to use Blocto
              Swap. Please check the "Credit my account" section to learn more
              about it.
            </v-row>
          </v-col>
        </v-row>
        <v-row justify="center" class="my-5">
          <v-col align="center">
            <v-row style="max-width: 800px" class="px-4 text-left">
              <p class="text-h5">Create an account</p>
            </v-row>
            <v-row style="max-width: 800px" class="px-4 text-left">
              To create an account, simply click on the "Log in / Sign up"
              button at the top right corner of the website. You will be asked
              to enter an email address which will contain a sign up link.
              <span class="font-weight-bold red--text text--darken-3"
                >You need to open this link in the same browser you entered your
                email address.</span
              >
              The following set up tasks will take place:
            </v-row>
            <v-row style="max-width: 800px" class="px-4 text-left">
              <ul class="mt-4">
                <li>Choose your username.</li>
                <li>
                  Connect to your Blocto wallet. This wallet cannot be already
                  linked to an account.
                </li>
                <li>
                  Accept a transaction which will perform two tasks: link your
                  Baguette account to your Blocto wallet and set up your wallet
                  to be able to receive records.
                </li>
                <li>That's it, you're good to go!</li>
              </ul>
            </v-row>
          </v-col>
        </v-row>
        <v-row justify="center" class="my-5">
          <v-col align="center">
            <v-row style="max-width: 800px" class="px-4 text-left">
              <p class="text-h5">Credit my account</p>
            </v-row>
            <v-row style="max-width: 800px" class="px-4 text-left">
              ₣USD is a stablecoin available everywhere and backed by Prime
              Trust. The simplest way to obtain it is to exchange it for Flow
              tokens, which can be obtained on any compatible exchange. However,
              Flow tokens are not available yet in certain countries, like the
              US and Canada. However, Blocto Swap allows you to exchange ₣USD
              for USDT, which are available everywhere. The following step can
              be followed to obtains ₣USD:
            </v-row>
            <v-row style="max-width: 800px" class="px-4 text-left">
              <ul class="mt-4">
                <li>
                  <b>You are not able to buy Flow tokens</b>: you will need to
                  exchange USDT (the dollar stablecoin on Ethereum blockchain)
                  for tUSDT via Blocto Swap. You might be charged for a "gas
                  fee" due to the interaction with the Ethereum blockchain. You
                  will then be able to exchange your tUSDT for ₣USD.
                </li>
                <li>
                  <b>You are able to buy Flow tokens</b>: you can simply
                  transfer your Flow tokens bought on any exchange supporting
                  Flow onto your Blocto wallet and then use Blocto Swap to
                  convert your Flow in ₣USD.
                </li>
              </ul>
            </v-row>
            <v-row style="max-width: 800px" class="px-4 text-left">
              <span class="mt-4 font-weight-bold red--text text--darken-3"
                >Make sure to transfer the ₣USD to the same Blocto account that
                is used on Baguette.</span
              >You can check the address of your linked Blocto account in the
              Account section on our website. No additional steps are needed
              once the ₣USD are into your Blocto wallet.
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
    <!-- <v-row justify="center" class="yellow lighten-5" fluid>
      <p class="text-h4 mx-5 mb-3" transition="scroll-y-transition">Get started</p>
    </v-row>
    <v-row justify="center" align="center" class="yellow lighten-5" fluid>
      <p class="text-h6"> A couple of links </p>
    </v-row>
    <v-row justify="center" align="center" class="yellow lighten-5" fluid>
      Create an account
    </v-row>
    <v-row justify="center" align="center" class="yellow lighten-5" fluid>
      Fund my account
    </v-row>
    <v-row justify="center" align="center" class="yellow lighten-5" fluid>
      Start collecting
    </v-row> -->
  </v-container>
</template>

<script>
export default {
  name: "GetStarted",
  data: () => ({}),
};
</script>

