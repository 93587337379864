import * as fcl from "@onflow/fcl"
import {
    auth
} from "@/firebase";

import {
    userStatus
} from './index'

import {
    getAddress,
    addressTaken,
    checkWalletAssociation,
    sendLinkDemand,
    checkWalletSetup,
    setupWallet
} from '@/services/WalletService'

import {
    getUsername,
    changeUsername
} from '@/services/UserService.js'

const actions = {
    // Keep track of firebase authentification
    authAction({
        getters,
        commit,
        dispatch
    }) {
        auth.onAuthStateChanged(user => {
            if (getters.getStatus !== userStatus.CONNECTED)
                commit("setStatus", userStatus.LOADING);
            if (user) {
                commit("setUser", user);
            } else {
                commit("setUser", null);
            }
            dispatch("computeUserStatus");
        });
    },

    // Sign in and out of firebase functionalities
    //
    // Send email for authentification, changing the sign in email
    sendAuthEmail({
        commit
    }, email) {
        const actionCodeSettings = {
            url: `${location.origin}/onboarding`,
            handleCodeInApp: true
        }
        commit('setSignInEmail', email);
        return auth.sendSignInLinkToEmail(email, actionCodeSettings);
    },

    // Signing in with firebase
    async signInWithLink({
        state
    }, url) {
        if (auth.isSignInWithEmailLink(url)) {
            return auth.signInWithEmailLink(state.signInEmail, url);
        }
    },

    // Logging out the firebase user
    logout({
        commit
    }) {
        return auth.signOut().then(() => {
            commit("setSignInEmail", null);
        });
    },

    // Compute what is the log in status of the user
    async computeUserStatus({
        state,
        commit,
        dispatch
    }) {
        var profile = {};

        // User is not connected
        if (!state.user) {
            commit("setStatus", userStatus.NONE);
            commit("setProfile", profile);
            return
        }

        let username = await getUsername(state.user.uid);
        if (!username) {
            // User connected but without username set
            commit("setStatus", userStatus.NOUSERNAME);
            commit("setProfile", profile);
            return
        }
        profile['username'] = username;

        let pubaddr = await getAddress();
        let snapshot = await fcl.currentUser().snapshot()
        let connectedAddress = snapshot.addr

        if (!pubaddr) {
            // User connected with a username, but no wallet attached
            if (!connectedAddress) {
                // There is no wallet connected
                commit("setStatus", userStatus.NOADDRESS);
                commit("setProfile", profile);
                return
            } else {
                let taken = await addressTaken(connectedAddress);
                if (taken) {
                    // This wallet is already associated with a user
                    commit("setStatus", userStatus.ADDRESSTAKEN);
                    commit("setProfile", profile);
                    await dispatch("logoutWallet");
                    return
                } else {
                    // The wallet is connected and available but detached
                    commit("setStatus", userStatus.DETACHEDADDRESS);
                    commit("setProfile", profile);
                    return
                }
            }
        } else {
            // User has a connected wallet
            if (!connectedAddress) {
                // The wallet is not connected
                commit("setStatus", userStatus.DISCONNECTEDWALLET);
                commit("setProfile", profile);
                return
            } else {
                let correctWallet = await checkWalletAssociation(connectedAddress);
                if (!correctWallet) {
                    // This user and wallet are attached to different accounts
                    commit("setStatus", userStatus.WRONGADDRESS);
                    commit("setProfile", profile);
                    return
                }
            }
        }

        let walletSetup = await checkWalletSetup(pubaddr);
        if (!walletSetup) {
            // The wallet of the user is not properly setup
            commit("setStatus", userStatus.NOTSETUPWALLET);
            commit("setProfile", profile);
            return
        }

        commit("setStatus", userStatus.CONNECTED);
        commit("setProfile", profile);
        return
    },

    // Change username and recompute the user status
    changeUsername({
        commit,
        dispatch
    }, username) {
        commit("setStatus", userStatus.LOADING);
        return changeUsername(username)
            .then(() => {
                dispatch('computeUserStatus');
            }).catch(() => {
                commit("setStatus", userStatus.ERROR);
            });
    },

    // Log in and out of wallet providers
    //
    loginWallet({
        commit,
        dispatch
    }) {
        commit("setStatus", userStatus.LOADING);
        return fcl.currentUser().authenticate().then(user => {
            commit("setAddress", user.addr);
            dispatch("computeUserStatus");
        }).catch(() => {
            commit("setStatus", userStatus.ERROR);
            dispatch("logoutWallet");
        });
    },

    logoutWallet({
        commit
    }) {
        fcl.currentUser().unauthenticate()
        commit("setAddress", null);
        return;
    },

    // Attach the wallet to the account and prepare it for the on chain operations of the platform
    //
    attachWallet({
        commit,
        dispatch
    }) {
        commit("setStatus", userStatus.LOADING);
        return fcl.currentUser().snapshot().then(snapshot => {
            let connectedAddress = snapshot.addr
            if (!connectedAddress)
                throw new Error("No address connected!")
            return sendLinkDemand(connectedAddress)
        }).then(() => {
            dispatch("computeUserStatus");
        }).catch(() => {
            commit("setStatus", userStatus.ERROR);
            dispatch("logoutWallet");
        })
    },

    prepareWallet({
        commit,
        dispatch
    }) {
        commit("setStatus", userStatus.LOADING);
        return setupWallet().then(() => {
            dispatch("computeUserStatus");
        }).catch((e) => {
            console.log(e)
            commit("setStatus", userStatus.ERROR);
            dispatch("logoutWallet");
        })
    },
};

export default actions;