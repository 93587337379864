<template>
  <v-container>
    <v-row class="py-0 font-weight-medium text-subtitle" justify="center">
      ₣USD
      <v-hover v-slot="{ hover }">
        <router-link
          :to="recordlink"
          :class="{
            'black--text': !hover,
            'grey--text text--darken-2': hover,
          }"
          class="text-decoration-none opacityanimated pl-1"
        >
          {{ getCurrentBid() }}
        </router-link>
      </v-hover>
    </v-row>
    <v-row v-if="!!this.bidder" class="pt-0 text-caption" justify="center">
      Current bid by
      <v-hover v-slot="{ hover }">
        <router-link
          :to="bidderlink"
          :class="{ 'text-decoration-none': !hover }"
          class="black--text pl-1"
        >
          @{{ bidder }}
        </router-link>
      </v-hover>
    </v-row>
    <v-row v-if="!this.bidder" class="pt-0 text-caption" justify="center">
      Starting bid
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "CardAuctionPrice",
  props: ["recordlink", "bidderlink", "nextminbid", "currentbid", "bidder"],
  methods: {
    getCurrentBid() {
      if (!this.bidder && this.nextminbid)
        return parseFloat(this.nextminbid).toFixed(2);
      else if (!this.bidder) return "-";
      else return parseFloat(this.currentbid).toFixed(2);
    },
  },
};
</script>