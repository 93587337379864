var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pb-1 text-subtitle text-left"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('router-link',{staticClass:"text-decoration-none",class:{
            'black--text': !hover,
            'grey--text text--darken-2': hover,
          },attrs:{"to":_vm.recordlink}},[_vm._v(" "+_vm._s(_vm.title)+" ")])]}}])})],1)],1),_c('v-row',{attrs:{"align":"center"}},[(!_vm.isSongReleased)?_c('v-col',{staticClass:"py-1 text-caption font-weight-light text-left orange--text text--darken-2",attrs:{"cols":"4"}},[_c('span',{staticClass:"amber lighten-1 rounded-pill pa-1 px-2 font-weight-medium white--text text--darken-2"},[_vm._v("UNRELEASED")])]):_vm._e(),(_vm.isSongReleased)?_c('v-col',{staticClass:"py-1 text-caption font-weight-light text-left grey--text",attrs:{"cols":"4"}},[_c('span',{staticClass:"ml-n2 rounded-pill pa-1 px-2 font-weight-medium grey--text text--darken-2"},[_vm._v("PUBLIC")])]):_vm._e(),_c('v-col',{staticClass:"py-1 font-weight-light text-right",attrs:{"cols":"12","sm":"8"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var hover = ref.hover;
return [_c('router-link',{staticClass:"black--text",class:{ 'text-decoration-none': !hover },attrs:{"to":_vm.artistlink}},[_vm._v(" "+_vm._s(_vm.artist)+" ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }