const axios = require("axios");

export function getCover(cid) {
    if(!cid)
        return
    return "https://baguette.mypinata.cloud/ipfs/" + cid
}

export function getAudio(cid, audiokey) {
    if(!cid||!audiokey)
        return
      
    return axios.get(process.env.VUE_APP_BACKEND_PATH + `/record/getaudio?audiokey=${audiokey}&cid=${cid}`)
}