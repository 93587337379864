import * as fcl from "@onflow/fcl"
import * as t from "@onflow/types"
import { replaceImports } from "./helper"

import getBalanceScript from '@/cadence/scripts/get_fusd_balance.cdc'

const CODE = replaceImports(getBalanceScript)

export function getFUSDBalance(address) {
  return fcl
    .send([
      fcl.script(CODE),
      fcl.args([fcl.arg(address, t.Address)]),
    ])
    .then(fcl.decode)
}