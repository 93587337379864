<template>
  <v-container class="py-0">
    <v-row class="mt-0" justify="end">
      <v-col class="pr-0 pb-0">
        <!-- :class="{'text-left': !$vuetify.breakpoint.xsOnly, 'text-center': $vuetify.breakpoint.xsOnly}" -->
        <p v-if="!!hasbid" class="text-h6 text-right">Current bid</p>
        <p v-if="!hasbid" class="text-h6 text-right">Starting price</p>
      </v-col>
    </v-row>
    <v-row class="mt-0" justify="end">
      <p class="text-h6">
        ₣USD <span class="opacityanimated">{{ getCurrentBid() }}</span>
      </p>
    </v-row>
    <v-row v-if="hasbid" class="mt-0" justify="end">
      <p class="text-subtitle-2 font-weight-light">
        by
        <v-hover v-slot="{ hover }">
          <router-link
            :to="bidderlink"
            :class="{ 'text-decoration-none': !hover }"
            class="black--text pl-0"
          >
            @{{ bidder }}
          </router-link>
        </v-hover>
      </p>
    </v-row>
    <v-row>
      <v-col class="pa-0 py-md-2">
        <v-divider />
      </v-col>
    </v-row>
    <v-row v-if="!$vuetify.breakpoint.xsOnly" class="mt-4">
      <v-col class="pr-0 pb-0" align="right">
        <v-hover v-slot="{ hover }">
          <router-link
            to="/faq"
            :class="{ 'text-decoration-none': !hover }"
            class="black--text text-caption pl-0"
          >
            How auctions work
          </router-link>
        </v-hover>
      </v-col>
    </v-row>
    <v-row v-if="!$vuetify.breakpoint.xsOnly" class="mt-4">
      <v-col v-if="!belongstouser" class="pr-0 pb-0" align="right">
        <v-btn
          v-if="!maintenance"
          depressed
          class="blue lighten-4"
          :disabled="maintenance||!hasstarted"
          @click="$emit('bidclicked')"
        >
          Bid
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.xsOnly" class="mt-4" justify="end" align="center">
      
      <v-col class="px-0 py-0" align="begin">
        <v-hover v-slot="{ hover }">
          <router-link
            to="/faq"
            :class="{ 'text-decoration-none': !hover }"
            class="black--text text-caption pl-0"
          >
            How auctions work
          </router-link>
        </v-hover>
      </v-col>
      <v-col cols="3" v-if="!belongstouser" class="px-0" align="end">
        <v-btn
          v-if="!maintenance"
          depressed
          class="blue lighten-4"
          :disabled="maintenance||!hasstarted"
          @click="$emit('bidclicked')"
        >
          Bid
        </v-btn>
      </v-col>
    </v-row>
    
    <v-row v-if="actionstatus === 'bidplaced'" justify="end">
      <div class="mt-3 green green--text text--darken-2 lighten-4 px-5 rounded">
        Bid has been placed
      </div>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ViewAuctionDetails",
  props: [
    "maintenance",
    "bidderlink",
    "bidder",
    "hasstarted",
    "belongstouser",
    "hasbid",
    "actionstatus",
    "nextminbid",
    "currentbid",
  ],
  methods: {
    getCurrentBid() {
      if (!this.bidder && this.nextminbid)
        return parseFloat(this.nextminbid).toFixed(2);
      else if (!this.bidder) return "-";
      else return parseFloat(this.currentbid).toFixed(2);
    },
  },
};
</script>