<template>
  <v-container>
      <!-- :class="{'text-left': !$vuetify.breakpoint.xsOnly, 'text-center': $vuetify.breakpoint.xsOnly}" -->
    <v-row class="mt-0" :class="{'justify-end': $vuetify.breakpoint.mdAndDown}">
      <div class="text-h4" :class="{'text-right': $vuetify.breakpoint.mdAndDown, 'text-left': !$vuetify.breakpoint.mdAndDown}">{{ title }}</div>
    </v-row>
    <v-row :class="{'justify-end': $vuetify.breakpoint.mdAndDown}">
      <v-col cols="12" class="px-0 pt-0">
        <v-container>
          <v-row :class="{'justify-end': $vuetify.breakpoint.mdAndDown}">
            <p class="mb-2 grey--text text-subtitle-2">
              <span class="text-h6">
                <v-hover v-slot="{ hover }">
                  <router-link
                    :to="artistlink"
                    :class="{ 'text-decoration-none': !hover }"
                    class="black--text mx-0"
                  >
                    {{ artist }}
                  </router-link>
                </v-hover></span
              >
            </p>
          </v-row>
          <v-row v-if="!isSongReleased" :class="{'justify-end': $vuetify.breakpoint.mdAndDown}">
            <span
              class="mt-5 amber lighten-1 rounded-pill pa-1 px-2 font-weight-medium white--text text--darken-2"
              >UNRELEASED</span
            >
          </v-row>
          <v-row v-if="isSongReleased" :class="{'justify-end': $vuetify.breakpoint.mdAndDown}">
            <span
              class="mt-5 ml-n2 grey lighten-3 rounded-pill pa-1 px-2 font-weight-medium grey--text text--darken-2"
              >PUBLIC</span
            >
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row v-if="owner" class="text-subtitle-1" :class="{'justify-end': $vuetify.breakpoint.mdAndDown}">
      Currently owned by
            <v-hover v-slot="{ hover }">
              <router-link
                :to="ownerlink"
                :class="{ 'text-decoration-none': !hover}"
                class="black--text pr-0 pl-2"
              >
                @{{ owner }}
              </router-link>
            </v-hover>
    </v-row>
    <v-row v-if="lastprice" class="text-subtitle-1" :class="{'justify-end': $vuetify.breakpoint.mdAndDown}">
      Last sold for ₣USD {{ parseFloat(lastprice).toFixed(2) }}
    </v-row>
    <v-row v-if="!lastprice" class="text-subtitle-1" :class="{'justify-end': $vuetify.breakpoint.mdAndDown}">
      Never sold
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ViewDescription",
  props: ["ownerlink", "artistlink", "title", "owner", "artist", "isSongReleased", "lastprice"],
};
</script>