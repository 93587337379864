const axios = require("axios");

import {
    offersCollection
} from "@/firebase";
import { makeOfferTx } from "../cadence/make-offer.tx";
import { cancelOfferTx } from "../cadence/cancel-offer.tx";
import { acceptDirectOfferTx } from "../cadence/accept-direct-offer.tx";
import { acceptAuctionOfferTx } from "../cadence/accept-auction-offer.tx";

export function getRecentOffers(limit = 3, pagination = null) {
    var query = offersCollection.orderBy("timestamp_market", "desc")
    if (pagination)
        query = query.startAfter(pagination)

    return query.limit(limit).get()
}

export function getOfferDetails(offerid) {
    return axios.get(process.env.VUE_APP_BACKEND_PATH + '/offer/get', {
            params: {
                offerid: offerid
            }
        })
        .then((res) => {
            return res.data.offer;
        });
}

export function makeOffer(recordid, amount) {
    return makeOfferTx(recordid, amount)
}

export function cancelOffer(recordid) {
    return cancelOfferTx(recordid)
}

export function acceptDirectOffer(recordid) {
    return acceptDirectOfferTx(recordid)
}

export function acceptAuctionOffer(recordid) {
    return acceptAuctionOfferTx(recordid)
}

