const getters = {
    getSignInEmail(state) {
        return state.signInEmail;
    },
    getUser(state) {
        return state.user;
    },
    getProfile(state) {
        return state.profile;
    },
    getAddress(state) {
        return state.address;
    },
    isUserAuth(state) {
        return !!state.user;
    },
    getStatus(state) {
        return state.status;
    }
};

export default getters;
