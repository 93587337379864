const mutations = {
    setSignInEmail(state, payload) {
        state.signInEmail = payload;
    },
    setUser(state, payload) {
        state.user = payload;
    },
    setProfile(state, payload) {
        state.profile = payload;
    },
    setAddress(state, payload) {
        state.address = payload;
    },
    setStatus(state, payload) {
        state.status = payload;
    }
};

export default mutations;
