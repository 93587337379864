<template>
  <v-container>
    <v-row class="py-0 font-weight-medium text-subtitle" justify="center">
      ₣USD
      <v-hover v-slot="{ hover }">
        <router-link
          :to="recordlink"
          :class="{
            'black--text': !hover,
            'grey--text text--darken-2': hover,
          }"
          class="text-decoration-none opacityanimated pl-1"
        >
          {{ getCurrentOffer() }}
        </router-link>
      </v-hover>
    </v-row>
    <v-row v-if="!!this.offerer" class="pt-0 text-caption" justify="center">
      Current offer by
      <v-hover v-slot="{ hover }">
        <router-link
          :to="offererlink"
          :class="{ 'text-decoration-none': !hover }"
          class="black--text pl-1"
        >
          @{{ offerer }}
        </router-link>
      </v-hover>
    </v-row>
    <v-row v-if="!this.offerer" class="pt-0 text-caption" justify="center">
      No offers yet
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "CardRecordPrice",
  props: ["recordlink", "offererlink", "nextminoffer", "currentoffer", "offerer"],
  methods: {
    getCurrentOffer() {
      if (!this.offerer && this.nextminoffer)
        return parseFloat(this.nextminoffer).toFixed(2);
      else if (!this.offerer) return "-";
      else return parseFloat(this.currentoffer).toFixed(2);
    },
  },
};
</script>