const axios = require("axios");

import {
    artistsCollection,
    recordsCollection
} from "@/firebase";

import { unlockRecordTx } from "../cadence/unlock-record.tx";

export function getArtistSlug(artistID) {
    return artistsCollection.doc(String(artistID)).get()
    .then(snapshot => {
        
        return snapshot.data().slug
    })
}

export function getArtistName(slug) {
    return artistsCollection.where("slug", "==", slug).limit(1).get()
    .then(query => {
        if(query.size == 0)
            return null
        
        return query.docs[0].data().name
    })
}

export function getRecordsByArtistSlug(artistName, limit = 3, pagination = null) {
    return artistsCollection.where("slug", "==", artistName).limit(1).get()
    .then(query => {
        if(query.size == 0)
            return null
        
        return getArtistRecords(parseInt(query.docs[0].id), limit, pagination)
    })
}

export function getArtistRecords(artistID, limit = 3, pagination = null) {
    var query = recordsCollection.where("artistID", "==", artistID)
    if (pagination)
        query = query.startAfter(pagination)

    return query.limit(limit).get()
}

export function getRecordDetails(recordid) {
    return axios.get(process.env.VUE_APP_BACKEND_PATH + '/record/get', {
            params: {
                recordid: recordid
            }
        })
        .then((res) => {
            return res.data.record;
        });
}

export function getRecordAuctionID(recordid) {
    return axios.get(process.env.VUE_APP_BACKEND_PATH + '/auction/getrecordauctionid', {
            params: {
                recordid: recordid
            }
        })
        .then((res) => {
            return res.data.auctionID;
        });
}

export function getRecordOfferID(recordid) {
    return axios.get(process.env.VUE_APP_BACKEND_PATH + '/offer/getrecordofferid', {
            params: {
                recordid: recordid
            }
        })
        .then((res) => {
            return res.data.offerID;
        });
}

export function getRecordLastPrices(recordid) {
    return axios.get(process.env.VUE_APP_BACKEND_PATH + '/record/lastprices', {
            params: {
                recordid: recordid
            }
        })
        .then((res) => {
            return res.data;
        });
}

export function getRecordsUser(user) {
    return axios.get(process.env.VUE_APP_BACKEND_PATH + '/record/fromuser', {
        params: {
            name: user
        }
    })
    .then((res) => {
        return res.data;
    });
}

export function unlockRecord(recordid) {
    return unlockRecordTx(recordid)
}
