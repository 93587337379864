import Vue from 'vue'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import "firebase/analytics"

const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING,
    appId: process.env.VUE_APP_FIREBASE_APPID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASURMENT
};
firebase.initializeApp(firebaseConfig)
firebase.analytics();
Vue.prototype.$analytics = firebase.analytics();

// utils
const db = firebase.firestore()
const auth = firebase.auth()
const fct = firebase.functions()

// collection references
const usersCollection = db.collection('users')
const artistsCollection = db.collection('artists')
const balanceCollection = db.collection('user-balance')
const songsCollection = db.collection('songs')
const recordsCollection = db.collection('records')
const salesCollection = db.collection('sales')
const offersCollection = db.collection('offers')
const auctionsCollection = db.collection('auctions')
const announcesCollection = db.collection('announced')

// export utils/refs
export {
    db,
    auth,
    fct,
    artistsCollection,
    usersCollection,
    balanceCollection,
    songsCollection,
    recordsCollection,
    salesCollection,
    offersCollection,
    auctionsCollection,
    announcesCollection
}
