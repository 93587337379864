<template>
  <v-container class="pa-0">
    <audio :src="audio" style="display: none" ref="player"/>
    <v-hover v-if="!!audiokey" v-slot="{ hover }">
      <v-card max-height="325" elevation="0">
        <v-img :src="image" aspect-ratio="1" alt="Album picture">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="amber lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>

        <v-overlay absolute :value="hover || isPlaying" z-index="0">
          <v-container>
            <v-row justify="center">
              <v-btn
                icon
                x-large
                :disabled="!audioLoaded"
                v-if="!isPlaying"
                @click="toggleAudio()"
              >
                <v-icon size="70">mdi-play-circle-outline</v-icon>
              </v-btn>
              <v-btn
                icon
                x-large
                :disabled="!audioLoaded"
                v-if="isPlaying"
                @click="
                  toggleAudio()
                "
              >
                <v-icon size="70">mdi-pause-circle-outline</v-icon>
              </v-btn>
            </v-row>
            <v-row class="mt-5" justify="center" align="center">
              <v-btn icon @click="reset()"><v-icon>mdi-replay</v-icon> </v-btn>
              <p class="mb-0 ml-1">{{ timePrint }}</p>
            </v-row>
          </v-container>
        </v-overlay>
      </v-card>
    </v-hover>
    <v-card v-if="!audiokey" max-height="325" elevation="0"  class="brown lighten-5">
      <router-link :to="recordlink">
        <v-img :src="image" aspect-ratio="1" alt="Album picture">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="amber lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </router-link>
    </v-card>
  </v-container>
</template>

<script>
import { getCover, getAudio } from "@/services/StorageService";
export default {
  name: "CardImage",
  data: () => {
    return {
      playbackTime: 0,
      audioDuration: 100,
      audioLoaded: false,
      isPlaying: false,
      timePrint: "00:00",
      audio: null,
      image: null
    };
  },
  props: ["recordlink", "coveraddr", "audiokey", "audioaddr"],
  watch: {
    audioaddr: function (val) {
      if(!val||!this.audiokey)
        return
      
      getAudio(val, this.audiokey)
        .then(query => {
          this.audio = query.data
        }).catch(error => {
          console.error(error)
        })
    },
    coveraddr: function (val) {
      if(!val)
        return
      
      this.image = getCover(val)
    },
  },
  methods: {
    initSlider() {
      var audio = this.$refs.player;
      if (audio) {
        this.audioDuration = Math.round(audio.duration);
      }
    },
    //Convert audio current time from seconds to min:sec display
    convertTime(seconds) {
      const format = (val) => `0${Math.floor(val)}`.slice(-2);
      // var hours = seconds / 3600;
      var minutes = (seconds % 3600) / 60;
      return [minutes, seconds % 60].map(format).join(":");
    },
    //Show the total duration of audio file
    totalTime() {
      var audio = this.$refs.player;
      if (audio) {
        var seconds = audio.duration;
        return this.convertTime(seconds);
      } else {
        return "00:00";
      }
    },
    //Display the audio time elapsed so far
    elapsedTime() {
      var audio = this.$refs.player;
      if (audio) {
        var seconds = audio.currentTime;
        return this.convertTime(seconds);
      } else {
        return "00:00";
      }
    },
    //Reset
    reset() {
      var audio = this.$refs.player;
      if (this.isPlaying) {
        
        this.toggleAudio();
        
        // audio.play();
      }
      audio.currentTime = 0;
      this.timePrint = "00:00";
    },
    //Playback listener function runs every 100ms while audio is playing
    playbackListener() {
      var audio = this.$refs.player;
      //Sync local 'playbackTime' var to audio.currentTime and update global state
      this.playbackTime = audio.currentTime;
      this.timePrint = this.elapsedTime();

      //console.log("update: " + audio.currentTime);
      //Add listeners for audio pause and audio end events
      audio.addEventListener("ended", this.endListener);
      audio.addEventListener("pause", this.pauseListener);
    },
    //Function to run when audio is paused by user
    pauseListener() {
      this.isPlaying = false;
      this.listenerActive = false;
      this.cleanupListeners();
    },
    //Function to run when audio play reaches the end of file
    endListener() {
      this.isPlaying = false;
      this.listenerActive = false;
      this.cleanupListeners();
    },
    //Remove listeners after audio play stops
    cleanupListeners() {
      var audio = this.$refs.player;
      audio.removeEventListener("timeupdate", this.playbackListener);
      audio.removeEventListener("ended", this.endListener);
      audio.removeEventListener("pause", this.pauseListener);
      //console.log("All cleaned up!");
    },
    toggleAudio() {
      var audio = this.$refs.player;
      //var audio = document.getElementById("audio-player");
      if (audio.paused) {
        audio.play();
        this.isPlaying = true;
      } else {
        audio.pause();
        this.isPlaying = false;
      }
    }
  },
  mounted: function () {
    // nextTick code will run only after the entire view has been rendered
    this.$nextTick(function () {
      var audio = this.$refs.player;
      //Wait for audio to load, then run initSlider() to get audio duration and set the max value of our slider
      // "loademetadata" Event https://www.w3schools.com/tags/av_event_loadedmetadata.asp
      audio.addEventListener(
        "loadedmetadata",
        function () {
          this.initSlider();
        }.bind(this)
      );
      // "canplay" HTML Event lets us know audio is ready for play https://www.w3schools.com/tags/av_event_canplay.asp
      audio.addEventListener(
        "canplay",
        function () {
          this.audioLoaded = true;
        }.bind(this)
      );
      //Wait for audio to begin play, then start playback listener function
      this.$watch("isPlaying", function () {
        if (this.isPlaying) {
          var audio = this.$refs.player;
          this.initSlider();
          //console.log("Audio playback started.");
          //prevent starting multiple listeners at the same time
          if (!this.listenerActive) {
            this.listenerActive = true;
            //for a more consistent timeupdate, include freqtimeupdate.js and replace both instances of 'timeupdate' with 'freqtimeupdate'
            audio.addEventListener("timeupdate", this.playbackListener);
          }
        }
      });
      //Update current audio position when user drags progress slider
      this.$watch("playbackTime", function () {
        // var audio = this.$refs.player;
        var diff = Math.abs(this.playbackTime - this.$refs.player.currentTime);

        //Throttle synchronization to prevent infinite loop between playback listener and this watcher
        if (diff > 0.01) {
          this.$refs.player.currentTime = this.playbackTime;
        }
      });
    });
  },
};
</script>