<template>
  <!-- hasoffer, actionstatus, offererlink, offerer, belongstouser -->
  <v-container class="mt-0">
    <v-row class="mt-0" justify="end">
      <v-col class="pr-0 pb-0">
        <p v-if="hasoffer" class="text-h6 text-right">Current Offer</p>
        <p v-if="!hasoffer" class="text-h6 text-right">No offers yet</p>
      </v-col>
    </v-row>
    <v-row class="mt-0" justify="end">
      <p class="text-h6">
        ₣USD <span class="opacityanimated">{{ getCurrentOffer() }}</span>
      </p>
    </v-row>
    <v-row v-if="hasoffer" class="mt-0" justify="end">
      <p class="text-subtitle-2 font-weight-light">
        by
        <v-hover v-slot="{ hover }">
          <router-link
            :to="offererlink"
            :class="{ 'text-decoration-none': !hover }"
            class="black--text pl-0"
          >
            @{{ offerer }}
          </router-link>
        </v-hover>
      </p>
    </v-row>
    <v-row>
      <v-col class="pa-0 py-md-2">
        <v-divider />
      </v-col>
    </v-row>
    <v-row class="mt-4">
      <v-col class="pr-0 pb-0" align="right">
        <v-hover v-slot="{ hover }">
          <router-link
            to="/faq"
            :class="{ 'text-decoration-none': !hover }"
            class="black--text text-caption pl-0"
          >
            How sales work
          </router-link>
        </v-hover>
      </v-col>
    </v-row>
    <v-row class="mt-4">
      <v-col v-if="!belongstouser" class="pr-0 pb-0" align="right">
        <v-btn
          v-if="!maintenance"
          depressed
          class="red lighten-4 mb-2"
          :disabled="maintenance||!(hasoffer&&hasmadeoffer&&canCancel)"
          @click="$emit('canceloffer')"
        >
          Cancel Offer
        </v-btn>
        <v-btn
          v-if="!maintenance"
          depressed
          class="blue lighten-4 ml-2 mb-2"
          @click="$emit('placeoffer')"
          :disabled="maintenance"
        >
          Offer
        </v-btn>
      </v-col>
      <v-col v-if="belongstouser" class="pr-0 pb-0" align="right">
        <v-btn
          v-if="!maintenance"
          depressed
          class="blue lighten-4 ml-2 mb-2"
          :disabled="maintenance||!hasoffer"
          @click="$emit('acceptsale')"
        >
          Accept
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="actionstatus === 'offeraccepted'" justify="end">
      <div class="mt-3 green green--text text--darken-2 lighten-4 px-5 rounded">
        The offer has been accepted
      </div>
    </v-row>
    <v-row v-if="actionstatus === 'offercanceled'" justify="end">
      <div class="mt-3 green green--text text--darken-2 lighten-4 px-5 rounded">
        Your offer has been canceled
      </div>
    </v-row>
    <v-row v-if="actionstatus === 'offermade'" justify="end">
      <div class="mt-3 green green--text text--darken-2 lighten-4 px-5 rounded">
        Your offer has been made
      </div>
    </v-row>
    <v-row v-if="actionstatus === 'auctionstarted'" justify="end">
      <div class="mt-3 green green--text text--darken-2 lighten-4 px-5 rounded">
        An auction has been created
      </div>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ViewRecordDetails",
  props: [
    "maintenance",
    "hasoffer",
    "hasmadeoffer",
    "canCancel",
    "belongstouser",
    "actionstatus",
    "offererlink",
    "offerer",
    "nextminoffer",
    "currentoffer",
  ],
  methods: {
    getCurrentOffer() {
      if (!this.offerer && this.nextminoffer)
        return parseFloat(this.nextminoffer).toFixed(2);
      else if (!this.offerer) return "-";
      else return parseFloat(this.currentoffer).toFixed(2);
    },
  },
};
</script>