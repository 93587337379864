import Vue from 'vue'
import VueRouter from 'vue-router'

import store, {
    userStatus
} from '../store' // your vuex store
import Home from '../views/Home.vue'
// import Market from '../views/Market.vue'
import Profile from '../views/Profile.vue'
import Record from '../views/Record.vue'
import Artist from '../views/Artist.vue'
import GetStarted from '../views/GetStarted.vue'

Vue.use(VueRouter)

async function commonGuard(to, from, next) {
    try {
        let wrongConnection = await store.getters.isUserAuth && (store.getters.getStatus !== userStatus.CONNECTED);
        let goodConnection = await store.getters.isUserAuth && (store.getters.getStatus === userStatus.CONNECTED);
        if (wrongConnection && to.name !== "Onboarding") {
            if (from.name === "Onboarding")
                return
            next('/onboarding')
        } else if (!goodConnection && to.name === "Account")
            next('/')
        else
            next()
    } catch (e) {
        console.log(e)
        next("/")
    }
}

const routes = [{
        path: '/',
        name: 'Home',
        component: Home,
        beforeEnter: commonGuard
    },
    {
        path: '/getstarted',
        name: 'GetStarted',
        component: GetStarted,
        beforeEnter: commonGuard
    },
    // {
    //     path: '/market',
    //     name: 'Market',
    //     component: Market,
    //     beforeEnter: commonGuard
    // },
    {
        path: '/account',
        name: 'Account',
        component: () => import( /* webpackChunkName: "account" */ '../views/Account.vue'),
        beforeEnter: commonGuard
        // beforeEnter: ifAuthenticated,
    },
    {
        path: '/onboarding',
        name: 'Onboarding',
        component: () => import( /* webpackChunkName: "account" */ '../views/Onboarding.vue'),
        beforeEnter: (to, from, next) => {
            let goodConnection = store.getters.isUserAuth && (store.getters.getStatus === userStatus.CONNECTED);
            if (goodConnection)
                next('/')
            else
                next()
        }
    },
    {
        path: '/about',
        name: 'About',
        component: () => import( /* webpackChunkName: "about" */ '../views/About.vue'),
        beforeEnter: commonGuard
    },
    {
        path: '/faq',
        name: 'FAQ',
        component: () => import( /* webpackChunkName: "faq" */ '../views/FAQ.vue'),
        beforeEnter: commonGuard
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () => import( /* webpackChunkName: "contact" */ '../views/Contact.vue'),
        beforeEnter: commonGuard
    },
    {
        path: '/record/:id',
        name: 'Record',
        component: Record,
        beforeEnter: commonGuard
    },
    {
        path: '/profile/:id',
        name: 'Profile',
        component: Profile,
        beforeEnter: commonGuard
    },
    {
        path: '/artist/:id',
        name: 'Artist',
        component: Artist,
        beforeEnter: commonGuard
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router