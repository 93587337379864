<template>
  <v-container>
    <v-row>
      <v-col class="pb-1 text-subtitle text-left">
        <v-hover v-slot="{ hover }">
          <router-link
            :to="recordlink"
            :class="{
              'black--text': !hover,
              'grey--text text--darken-2': hover,
            }"
            class="text-decoration-none"
          >
            {{ title }}
          </router-link>
        </v-hover>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col
        cols="4"
        class="py-1 text-caption font-weight-light text-left orange--text text--darken-2"
        v-if="!isSongReleased"
      >
        <span
          class="amber lighten-1 rounded-pill pa-1 px-2 font-weight-medium white--text text--darken-2"
          >UNRELEASED</span
        >
      </v-col>
      <v-col
        cols="4"
        class="py-1 text-caption font-weight-light text-left grey--text"
        v-if="isSongReleased"
      >
        <span
          class="ml-n2 rounded-pill pa-1 px-2 font-weight-medium grey--text text--darken-2"
          >PUBLIC</span
        >
      </v-col>
      <v-col cols="12" sm="8" class="py-1 font-weight-light text-right">
        <v-hover v-slot="{ hover }">
          <router-link
            :to="artistlink"
            :class="{ 'text-decoration-none': !hover }"
            class="black--text"
          >
            {{ artist }}
          </router-link>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "CardDescription",
  props: ["recordlink", "artistlink", "title", "artist", "isSongReleased"],
};
</script>