<template>
  <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
    <v-navigation-drawer :temporary="true" v-model="sidebar" app>
      <v-list>
        <v-list-item>
          <AccountMenu v-if="!isUserAuth"></AccountMenu>
        </v-list-item>
        <v-list-item v-for="item in links" :key="item.text" :to="item.route">
          {{ item.text }}
        </v-list-item>
        <v-list-item v-if="isUserAuth" to="/account"> Account </v-list-item>
        <v-list-item
          class="red lighten-4 rounded-pill mx-5 mt-5"
          v-if="isUserAuth"
          @click="performLogout()"
        >
          <v-spacer /> Log out <v-spacer />
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar elevate-on-scroll color="yellow lighten-5" app>
      <v-container>
        <!-- <v-row>
          <v-btn @click="cleanWalletFct()"> A </v-btn>
        </v-row> -->
        <v-row class="hidden-sm-and-up">
          <v-col cols="12" class="d-flex justify-space-between align-center">
            <span>
              <v-app-bar-nav-icon @click="sidebar = !sidebar">
              </v-app-bar-nav-icon>
            </span>
            <v-toolbar-title style="cursor: pointer" @click="goToHome()">
              Baguette
            </v-toolbar-title>
            <span style="width: 48px; height: 48px"></span>
          </v-col>
        </v-row>
        <v-row class="hidden-xs-only mt-0">
          <v-col
            xl="10"
            md="12"
            offset-xl="1"
            class="d-flex justify-center align-center"
          >
            <v-toolbar-title style="cursor: pointer" @click="goToHome()">
              Baguette
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items class="align-center">
              <router-link
                class="black--text ml-5"
                v-for="item in links"
                :key="item.text"
                :to="item.route"
              >
                {{ item.text }}
              </router-link>
              <AccountMenu class="ml-3"></AccountMenu>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-main>
      <v-container>
        <v-row justify="center">
          <v-alert v-if="maintenance" dense type="warning" outlined border="left"
            >We are under maintenance! No transaction will be allowed for the
            moment.</v-alert
          >
        </v-row>
      </v-container>
      <router-view></router-view>
    </v-main>

    <v-footer class="mt-5" color="yellow lighten-5" padless>
      <v-card
        flat
        tile
        class="yellow lighten-5 grey--text text--darken-1 text-center"
        style="width: 100%"
      >
        <router-link
          class="grey--text text--darken-1 text-decoration-none ml-5"
          v-for="item in footerLinks"
          :key="item.text"
          :to="item.route"
        >
          {{ item.text }}
        </router-link>
        <v-card-text>
          <v-btn
            v-for="icon in icons"
            :key="icon.link"
            class="mx-4 grey--text text--darken-1"
            icon
            :href="icon.link"
          >
            <v-icon size="24px">
              {{ icon.icon }}
            </v-icon>
          </v-btn>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="grey--text text--darken-1">
          <v-container>
            <v-row justify="center">
              <a
                class="text-decoration-none grey--text text--darken-1 pt-0"
                href="https://www.notion.so/Baguette-Terms-and-Conditions-6b02cc6023ca4681b9a6c911a118495b"
              >
                Terms and Conditions
              </a>
              <a
                class="text-decoration-none grey--text text--darken-1 pb-1 ml-3"
                href="https://www.notion.so/Baguette-Privacy-Notice-8d96d1d9641f47a68bf8f93b868b713c"
              >
                Privacy statement
              </a>
            </v-row>
            <v-row justify="center">
              {{ new Date().getFullYear() }} — <strong>Baguette</strong>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import AccountMenu from "./components/AccountMenu";
import { mapGetters, mapActions } from "vuex";
import { checkIfChainInteraction } from "./services/UserService"

// import { cleanWallet } from "./cadence/dangerous-clean-wallet.tx"

export default {
  name: "App",
  mounted() {
    checkIfChainInteraction().then(res => {
      this.maintenance = !res.data
    })
    this.authAction();
  },
  computed: {
    ...mapGetters(["isUserAuth"]),
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  methods: {
    ...mapActions(["authAction", "logout"]),
    goToHome() {
      if (this.$route.path != "/") {
        this.$router.push("/");
      }
    },
    performLogout() {
      this.logout().then(() => {
        // this.$router.push('/');
        console.info("NYI: check routing at log out");
        this.emailSent = false;
      });
    },
    // cleanWalletFct() {
    //   cleanWallet()
    // }
  },
  data: () => ({
    sidebar: false,
    maintenance: false,
    links: [
      {
        text: "Home",
        route: "/",
      },
      // {
      //   text: "Market",
      //   route: "/market",
      // },
      {
        text: "About",
        route: "/about",
      },
      {
        text: "FAQ",
        route: "/faq",
      },
    ],
    footerLinks: [
      {
        text: "Home",
        route: "/",
      },
      {
        text: "About",
        route: "/about",
      },
      {
        text: "Contact",
        route: "/contact",
      },
    ],
    icons: [
      { link: "https://twitter.com/baguetterecords", icon: "mdi-twitter" },
      { link: "https://discord.gg/JHxkSF4AR5", icon: "mdi-discord" },
    ],
  }),
  components: {
    AccountMenu,
  },
};
</script>
