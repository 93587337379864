import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate'
import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

Vue.use(Vuex);

export const userStatus = {
    NONE: "none", // no user connected
    NOUSERNAME: "nousername", // user has not set username yet
    NOADDRESS: "noaddress", // user has no wallet attached and no wallet connected
    DETACHEDADDRESS: "detachedaddress", // user and wallet are connected and compatible but need to be attached
    ADDRESSTAKEN: "addresstaken", // the connected wallet (now logged out) was already taken by another account
    WRONGADDRESS: "wrongaddress", // the connected wallet (now logged out) did not correspond to the user one
    DISCONNECTEDWALLET: "disconnectedwallet", // user has a wallet attached but no wallet connected
    NOTSETUPWALLET: "notsetupwallet", // user has the good wallet attached but it is not properly setup yet
    CONNECTED: "connected", // all good
    LOADING: "loading", // an log in operation is being performed
    ERROR: "error" //an error happened
}

const initialState = () => {
    return {
        signInEmail: null,
        user: null,
        status: null,
        address: null,
        profile: {}
    };
};

const store = new Vuex.Store({
    plugins: [createPersistedState()],
    state: initialState(),
    mutations: mutations,
    actions: actions,
    getters: getters
});

export default store;
