import * as fcl from "@onflow/fcl"
import * as t from "@onflow/types"
import { replaceImports } from "./helper"

import checkAccountScript from '@/cadence/scripts/check_account.cdc'

const CODE = replaceImports(checkAccountScript)

export function checkWallet(address) {
  return fcl
    .send([
      fcl.script(CODE),
      fcl.args([fcl.arg(address, t.Address)]),
    ])
    .then(fcl.decode)
}