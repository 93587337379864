<template>
  <v-container fill-height class="mt-4">
    <AuctionView :id="auctionid" v-if="auctionid" />
    <RecordView v-on:reload="loadRecordStatus()" :id="id" v-if="!auctionid" />
  </v-container>
</template>

<script>
import AuctionView from "@/components/AuctionView"
import RecordView from "@/components/RecordView"
import { getRecordAuctionID } from "@/services/RecordService.js";
export default {
  name: "Record",
  data() {
    return {
      id: "",
      auctionid: null
    };
  },
  components: {
    RecordView,
    AuctionView
  },
  methods: {
    loadRoute() {
      this.id = this.$route.params.id;
    },
    async loadRecordStatus() {
      this.auctionid = await getRecordAuctionID(this.id)
    }
  },
  created() {
    this.loadRoute();
    this.loadRecordStatus();
  },
};
</script>

<style>
.opacityanimated {
  -webkit-animation-name: opacity;
  -webkit-animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-name: opacity;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

@keyframes opacity {
  0%,
  25%,
  75%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
}

@-webkit-keyframes opacity {
  0%,
  25%,
  75%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
}
</style>
