<template>
  <v-container class="py-0">
    <v-row align="center" class="mt-1">
      <!-- <v-col v-if="belongstouser || !isauth" cols="4" class="pt-0 mb-4">
        <v-btn small depressed class="grey lighten-2" :to="recordlink"
          >Check record</v-btn
        >
      </v-col>
      <v-col v-if="!belongstouser && isauth" cols="4" class="pt-0 mb-4">
        <v-btn small depressed class="grey lighten-2" :to="recordlink"
          >Make offer</v-btn
        >
      </v-col> -->
      <v-col cols="8" offset="4" class="pt-0">
        <v-container class="pt-0 mb-4">
          <v-row class="mt-0 text-right text-caption" justify="end">
              Owner
          </v-row>
          <v-row class="mt-2 text-right text-subtitle-2" justify="end">
              <v-hover v-slot="{ hover }">
                <router-link
                  :to="ownerlink"
                  :class="{ 'text-decoration-none': !hover }"
                  class="black--text"
                >
                  @{{ owner }}
                </router-link>
              </v-hover>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "CardRecordFooter",
  props: ["recordlink", "ownerlink", "owner", "belongstouser", "isauth"],
  methods: {},
};
</script>