import * as fcl from "@onflow/fcl"
import { replaceImports } from "./helper"

import setupAccountScript from '@/cadence/transactions/setup_account.cdc'

const CODE = replaceImports(setupAccountScript)

export async function prepareWallet() {
  const txId = await fcl
    .send([
      fcl.transaction(fcl.cdc(CODE)),
      fcl.payer(fcl.authz), // current user is responsible for paying for the transaction
      fcl.proposer(fcl.authz), // current user acting as the nonce
      fcl.authorizations([fcl.authz]), // current user will be first AuthAccount
      fcl.limit(200) // set the compute limit
    ])
    .then(fcl.decode)

  return fcl.tx(txId).onceSealed()
}