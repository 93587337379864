<template>
  <v-container class="pa-0">
    <v-card
      max-height="325"
      elevation="0"
      class="brown lighten-5"
    >
      <v-img aspect-ratio="1" alt="Album picture">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="brown lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "CardLoadingImage",
};
</script>