import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

const options = {
  theme: {
    themes: {
      light: {
        background: colors.yellow.lighten5, // Not automatically applied
      },
      dark: {
        background: colors.yellow.lighten5, // If not using lighten/darken, use base to return hex
      },
    },
  },
}

export default new Vuetify({
  ...options
});