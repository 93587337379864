<template>
  <v-container>
    <v-dialog v-model="showOfferPopUp" max-width="330">
      <v-card v-if="displayOfferPopUpState('loading')">
        <v-card-title> Loading </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            loading
            disabled
            color="blue-grey"
            class="ma-2 white--text"
            fab
          >
            <v-icon dark> mdi-cloud-upload </v-icon>
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
      <v-card v-if="displayOfferPopUpState('noauth')">
        <v-card-title class="headline"> Need log in </v-card-title>
        <v-card-text class="my-2">
          <p class="my-1 text-justify">
            You will need to log in to be able to place an offer or manage your
            sale.
          </p>
        </v-card-text>

        <v-card-actions>
          <v-row justify="center">
            <v-col cols="6" align="center">
              <v-btn
                depressed
                color="grey lighten-3"
                @click="
                  showOfferPopUp = false;
                  offerpopupstate = 'none';
                  amount = null;
                "
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
      <v-card v-if="displayOfferPopUpState('errorplaceoffer')">
        <v-card-title> Error </v-card-title>
        <v-card-text class="my-2">
          <p class="my-1 text-justify">
            An error occured. You might not have sufficient funds or a higher
            offer has already been made. Please allow for a couple of minutes
            for the website to update.
          </p>
        </v-card-text>

        <v-card-actions>
          <v-row justify="center">
            <v-col cols="6" align="center">
              <v-btn
                depressed
                color="grey lighten-3"
                @click="showOfferPopUp = false"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
      <v-card v-if="displayOfferPopUpState('errorcanceloffer')">
        <v-card-title> Error </v-card-title>
        <v-card-text class="my-2">
          <p class="my-1 text-justify">
            An error occured. You might not have already canceled your offer, or
            the offer might have already been accepted. Please allow for a
            couple of minutes for the website to update.

            {{ error }}
          </p>
        </v-card-text>

        <v-card-actions>
          <v-row justify="center">
            <v-col cols="6" align="center">
              <v-btn
                depressed
                color="grey lighten-3"
                @click="showOfferPopUp = false"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
      <v-card v-if="displayOfferPopUpState('placeoffer')">
        <v-card-title class="headline"> Place an offer </v-card-title>
        <v-card-text class="my-2">
          <v-row> Enter flow amount below. <span class="red--text"> You will not be able to cancel this offer before 24 hours. </span></v-row>
          <v-row> Minimum offer at ₣USD {{ startingPrice }} </v-row>
          <v-row justify="center">
            <v-text-field
              v-model="amount"
              placeholder="Amount"
              hide-details="true"
              prefix="₣USD"
            ></v-text-field>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="red lighten-4"
            @click="
              offerpopupstate = 'none';
              showOfferPopUp = false;
            "
          >
            Close
          </v-btn>
          <v-btn depressed color="blue lighten-4" @click="makeOffer()">
            Make offer
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="displayOfferPopUpState('canceloffer')">
        <v-card-title class="headline"> Cancel an offer </v-card-title>
        <v-card-text class="my-2">
          You have an offer at ₣USD {{ currentOffer }} right now. Are you sure
          you want to cancel it?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="red lighten-4"
            @click="showOfferPopUp = false"
          >
            Close
          </v-btn>
          <v-btn depressed color="blue lighten-4" @click="cancelOffer()">
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="displayOfferPopUpState('validationplaceoffer')">
        <v-card-title class="headline"> Offer placed </v-card-title>
        <v-card-text class="my-2 text-justify">
          Your offer has been made for ₣USD {{ amount }}. Please allow for a
          couple of minutes for the website to update.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="blue lighten-4"
            @click="showOfferPopUp = false"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="displayOfferPopUpState('validationcanceloffer')">
        <v-card-title class="headline"> Offer canceled </v-card-title>
        <v-card-text class="my-2 text-justify">
          Your offer has been canceled. Please allow for a couple of minute for
          the website to update.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="blue lighten-4"
            @click="showOfferPopUp = false"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showSalePopUp" max-width="330">
      <v-card v-if="displaySalePopUpState('loading')">
        <v-card-title> Loading </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            loading
            disabled
            color="blue-grey"
            class="ma-2 white--text"
            fab
          >
            <v-icon dark> mdi-cloud-upload </v-icon>
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
      <v-card v-if="displaySalePopUpState('erroracceptsale')">
        <v-card-title> Error </v-card-title>
        <v-card-text class="my-2">
          <p class="my-1 text-justify">
            An error occured. You might have already accepted this offer. Please
            allow for a couple of minutes for the website to update.

            {{ error }}
          </p>
        </v-card-text>

        <v-card-actions>
          <v-row justify="center">
            <v-col cols="6" align="center">
              <v-btn
                depressed
                color="grey lighten-3"
                @click="showSalePopUp = false"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
      <v-card v-if="displaySalePopUpState('noauth')">
        <v-card-title class="headline"> Need log in </v-card-title>
        <v-card-text class="my-2">
          <p class="my-1 text-justify">
            You will need to log in to be able to place or manage your offers.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-row justify="center">
            <v-col cols="6" align="center">
              <v-btn
                depressed
                color="grey lighten-3"
                @click="
                  showSalePopUp = false;
                  offerpopupstate = 'none';
                  amount = null;
                "
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
      <v-card v-if="displaySalePopUpState('accept')">
        <v-card-title class="headline"> Accept offer </v-card-title>
        <v-card-text class="mt-2 mb-0 pb-2 text-justify">
          <v-container>
            <v-row>
              The current offer is ₣USD {{ currentOffer }}. You can either
              directly accept the offer, or accept the offer as the first bid of
              a 2-day auction.
            </v-row>
            <v-row>
              <v-btn
                depressed
                class="mt-3"
                color="blue lighten-4"
                @click="salepopupstate = 'acceptdirectoffer'"
              >
                Accept directly
              </v-btn>
            </v-row>
            <v-row>
              <v-btn
                depressed
                class="mt-3 mb-0"
                color="blue lighten-4"
                @click="salepopupstate = 'acceptauctionoffer'"
              >
                Start auction
              </v-btn>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="red lighten-4"
            @click="
              salepopupstate = 'none';
              showSalePopUp = false;
            "
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="displaySalePopUpState('acceptdirectoffer')">
        <v-card-title class="headline"> Direct sale </v-card-title>
        <v-card-text class="my-2 text-justify">
          The current offer is ₣USD {{ currentOffer }}. Directly accepting this
          offer will transfer the record to the bidder and you will receive the
          correspond Flow in exchange. <br /><br />
          Are you sure you want to pursue?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="red lighten-4"
            @click="
              salepopupstate = 'none';
              showSalePopUp = false;
            "
          >
            Cancel
          </v-btn>
          <v-btn depressed color="blue lighten-4" @click="acceptDirectOffer()">
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="displaySalePopUpState('validationdirectoffer')">
        <v-card-title class="headline"> Offer accepted </v-card-title>
        <v-card-text class="my-2 text-justify">
          You accepted this offer! You will receive your tokens shortly. Please
          allow a couple of minute for the website to update.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="blue lighten-4"
            @click="showSalePopUp = false"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="displaySalePopUpState('acceptauctionoffer')">
        <v-card-title class="headline"> Start auction </v-card-title>
        <v-card-text class="my-2 text-justify">
          The current offer is ₣USD {{ currentOffer }}. Accepting this offer as
          a first bid will start a 2-day auction. An auction cannot be canceled.
          The first bid will be this current offer. <br /><br />
          Are you sure you want to pursue?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="red lighten-4"
            @click="
              salepopupstate = 'none';
              showSalePopUp = false;
            "
          >
            Cancel
          </v-btn>
          <v-btn depressed color="blue lighten-4" @click="acceptAuctionOffer()">
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="displaySalePopUpState('validationauctionoffer')">
        <v-card-title class="headline"> Auction started </v-card-title>
        <v-card-text class="my-2 text-justify">
          An auction with this offer as first bid has been started! Please allow
          a couple of minute for the website to update.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="blue lighten-4"
            @click="showSalePopUp = false"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="displaySalePopUpState('acceptoffernobid')">
        <v-card-title class="headline"> No offer yet </v-card-title>
        <v-card-text class="my-2 text-justify">
          Your record has no offer you can accept yet!
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="blue lighten-4"
            @click="
              showSalePopUp = false;
              salepopupstate = 'none';
            "
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row justify="center" align="center">
      <ViewRecordHeader />
    </v-row>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="6" lg="4" class="pt-0 px-4">
        <v-container class="mt-0">
          <ViewRecordDetails
            :maintenance="maintenance"
            :belongstouser="belongsToUser"
            :hasoffer="hasOffer"
            :hasmadeoffer="hasMadeOffer"
            :canCancel="canCancel"
            :actionstatus="actionStatus"
            :offererlink="offerorLink"
            :nextminoffer="data.nextMinOffer"
            :currentoffer="data.currentOffer"
            :offerer="offerorName"
            v-on:canceloffer="
              showOfferPopUp = true;
              offerpopupstate = 'canceloffer';
            "
            v-on:placeoffer="
              showOfferPopUp = true;
              offerpopupstate = 'placeoffer';
            "
            v-on:acceptsale="
              showSalePopUp = true;
              salepopupstate = 'accept';
            "
          />
        </v-container>
      </v-col>
      <v-col cols="12" sm="6" lg="4" align="center">
        <ViewImage
          :coveraddr="data.coveraddr"
          :audioaddr="data.audioaddr"
          :audiokey="data.audiokey"
        />
      </v-col>
      <v-col cols="12" lg="4" align="center" class="pt-0 pl-4">
        <v-container class="mt-0">
          <ViewDescription
            :ownerlink="ownerLink"
            :artistlink="artistLink"
            :title="data.title"
            :owner="ownerName"
            :artist="data.artist"
            :isSongReleased="isSongReleased"
            :lastprice="data.lastprice"
          />
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  getRecordDetails,
  getRecordOfferID,
  getArtistSlug,
  getRecordLastPrices
} from "@/services/RecordService";
import * as offersvc from "@/services/OfferService.js";
import { checkIfChainInteraction } from "@/services/UserService"
import { mapGetters } from "vuex";

import ViewRecordHeader from "./viewsection/recordheader";
import ViewImage from "./viewsection/image";
import ViewRecordDetails from "./viewsection/recorddetails";
import ViewDescription from "./viewsection/description";

export default {
  name: "RecordView",
  props: ["id"],
  components: {
    ViewRecordHeader,
    ViewImage,
    ViewRecordDetails,
    ViewDescription,
  },
  data() {
    return {
      data: {},
      amount: null,
      showOfferPopUp: false,
      offerpopupstate: "none",
      loadingOfferPopUp: false,
      showSalePopUp: false,
      salepopupstate: "none",
      loadingSalePopUp: false,
      actionStatus: "non",
      error: "",
      maintenance: true
    };
  },
  mounted() {
    checkIfChainInteraction().then(res => {
      this.maintenance = !res.data
    })
  },
  created() {
    this.getRecordData();
  },
  computed: {
    ...mapGetters(["isUserAuth", "getProfile"]),
    isSongReleased: function () {
      return !!this.data.audiokey;
    },
    hasOffer: function () {
      return this.data.currentOffer && parseFloat(this.data.currentOffer) > 0;
    },
    hasMadeOffer: function () {
      return this.hasOffer && this.offerorName === this.getProfile.username;
    },
    currentOffer: function () {
      return parseFloat(this.data.currentOffer).toFixed(2);
    },
    startingPrice: function () {
      if (!this.data.nextMinOffer) return 0.0;
      return parseFloat(this.data.nextMinOffer).toFixed(2);
    },
    canCancel: function () {
      if (!this.hasMadeOffer) return false;

      var yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      return 0 < Date.parse(yesterday) - Date.parse(this.data.timestamp_created);
    },
    offerorName: function () {
      return this.data.offeror;
    },
    ownerName: function () {
      if (!this.data.owner) return "No owner";
      else return this.data.owner;
    },
    recordLink: function () {
      return "/record/" + this.data.recordID;
    },
    artistLink: function () {
      return "/artist/" + this.data.artistslug;
    },
    ownerLink: function () {
      if (!this.data.owner) return "#";
      return "/profile/" + this.data.owner;
    },
    offerorLink: function () {
      if (!this.data.offeror) return "#";
      return "/profile/" + this.offerorName;
    },
    belongsToUser: function () {
      return this.data.owner === this.getProfile.username;
    },
  },
  methods: {
    displayOfferPopUpState(state) {
      if (!this.isUserAuth) return state === "noauth";
      else if (this.loadingOfferPopUp) return state === "loading";
      else if (state === "placeoffer" && this.isUserAuth)
        return this.offerpopupstate === "placeoffer";
      else if (state === "validationplaceoffer" && this.isUserAuth)
        return this.offerpopupstate === "validationplaceoffer";
      else if (state === "errorplaceoffer")
        return this.offerpopupstate === "errorplaceoffer";
      else if (state === "canceloffer" && this.isUserAuth && this.hasMadeOffer)
        return this.offerpopupstate === "canceloffer";
      else if (state === "validationcanceloffer" && this.isUserAuth)
        return this.offerpopupstate === "validationcanceloffer";
      else if (state === "errorcanceloffer")
        return this.offerpopupstate === "errorcanceloffer";
    },
    displaySalePopUpState(state) {
      let offerStates = [
        "accept",
        "acceptdirectoffer",
        "acceptauctionoffer",
        "validationauctionoffer",
        "validationdirectoffer",
      ];
      if (!this.isUserAuth) return state === "noauth";
      else if (this.loadingSalePopUp) return state === "loading";
      else if (
        state === "cancelsalenooffer" &&
        this.isUserAuth &&
        !this.hasOffer
      )
        return this.salepopupstate === "cancelsale";
      else if (
        state === "cancelsalewithoffer" &&
        this.isUserAuth &&
        this.hasOffer
      )
        return this.salepopupstate === "cancelsale";
      else if (state === "validationcancel" && this.isUserAuth)
        return this.salepopupstate === "validationcancel";
      else if (state === "accept" && this.isUserAuth && this.hasOffer)
        return this.salepopupstate === "accept";
      else if (
        state === "acceptdirectoffer" &&
        this.isUserAuth &&
        this.hasOffer
      )
        return this.salepopupstate === "acceptdirectoffer";
      else if (
        state === "acceptauctionoffer" &&
        this.isUserAuth &&
        this.hasOffer
      )
        return this.salepopupstate === "acceptauctionoffer";
      else if (
        state === "validationauctionoffer" &&
        this.isUserAuth &&
        this.hasOffer
      )
        return this.salepopupstate === "validationauctionoffer";
      else if (
        state === "validationdirectoffer" &&
        this.isUserAuth &&
        this.hasOffer
      )
        return this.salepopupstate === "validationdirectoffer";
      else if (
        state === "acceptoffernobid" &&
        this.isUserAuth &&
        !this.hasOffer
      )
        return offerStates.includes(this.salepopupstate);
      else if (state === "errorwithdrawsale")
        return this.salepopupstate === "errorwithdrawsale";
      else if (state === "erroracceptsale")
        return this.salepopupstate === "erroracceptsale";
    },
    async getRecordData() {
      let data = await getRecordDetails(this.id);
      let offerid = await getRecordOfferID(this.id);
      if (offerid) {
        let offer = await offersvc.getOfferDetails(offerid);
        data = Object.assign({}, data, offer);
      }

      let artistSlug = await getArtistSlug(data.artistID);
      data["artistslug"] = artistSlug;
      
      let prices = await getRecordLastPrices(this.id);
      if(prices.length > 0)
        data["lastprice"] = prices[0].price;

      this.data = data;
    },
    makeOffer() {
      this.loadingOfferPopUp = true;
      offersvc
        .makeOffer(parseInt(this.data.recordID), parseFloat(this.amount))
        .then(() => {
          this.offerpopupstate = "validationplaceoffer";
          this.loadingOfferPopUp = false;
          this.actionStatus = "offermade";
        })
        .catch((error) => {
          this.error = error
          this.offerpopupstate = "errorplaceoffer";
          this.loadingOfferPopUp = false;
        });
    },
    cancelOffer() {
      this.loadingOfferPopUp = true;
      offersvc
        .cancelOffer(parseInt(this.data.recordID))
        .then(() => {
          this.offerpopupstate = "validationcanceloffer";
          this.actionStatus = "offercanceled";
          console.log("Offer canceled");
          this.loadingOfferPopUp = false;
        })
        .catch((error) => {
          this.error = error
          this.offerpopupstate = "errorcanceloffer";
          this.loadingOfferPopUp = false;
        });
    },
    acceptDirectOffer() {
      this.loadingSalePopUp = true;
      offersvc
        .acceptDirectOffer(this.data.recordID)
        .then(() => {
          this.salepopupstate = "validationdirectoffer";
          this.actionStatus = "offeraccepted";
          console.log("Offer directly accepted!");
          this.loadingSalePopUp = false;
        })
        .catch((error) => {
          this.error = error
          this.salepopupstate = "erroracceptsale";
          this.loadingSalePopUp = false;
        });
    },
    acceptAuctionOffer() {
      this.loadingSalePopUp = true;
      offersvc
        .acceptAuctionOffer(this.data.recordID)
        .then(() => {
          this.salepopupstate = "validationauctionoffer";
          this.actionStatus = "auctionstarted";
          this.loadingSalePopUp = false;
        })
        .catch((error) => {
          this.error = error
          this.salepopupstate = "erroracceptsale";
          this.loadingSalePopUp = false;
        });
    },
  },
};
</script>

<style>
.opacityanimated {
  -webkit-animation-name: opacity;
  -webkit-animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-name: opacity;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

@keyframes opacity {
  0%,
  25%,
  75%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
}

@-webkit-keyframes opacity {
  0%,
  25%,
  75%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
}
</style>