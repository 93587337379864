<template>
  <v-container>
    <LoadingCard v-if="recordstatus === 'loading'" />
    <AuctionCard :id="auctionid" v-if="recordstatus === 'loaded' && auctionid" />
    <RecordCard :id="id" v-if="recordstatus === 'loaded' && !auctionid" />
  </v-container>
</template>

<script>
import LoadingCard from "./LoadingCard"
import AuctionCard from "./AuctionCard"
import RecordCard from "./RecordCard"
import { getRecordAuctionID } from "@/services/RecordService";
export default {
  name: "GenericCard",
  props: ["id"],
  data() {
    return {
      recordstatus: "loading",
      auctionid: null
    };
  },
  components: {
    LoadingCard,
    RecordCard,
    AuctionCard,
  },
  methods: {
    async loadRecordStatus() {
      this.auctionid = await getRecordAuctionID(this.id)
      this.recordstatus = "loaded"
    }
  },
  created() {
    this.loadRecordStatus();
  },
};
</script>